function addNextPage(offerte)
{
    var get = getQueryParams(document.location.search);

    if (window.globalVar) {
        var url = window.globalVar;
    } else {
        if (offerte) {
            if ($('.mijnoffertesblokken').length <= 20) {
                var url = '/get/offertes?page=2';
            }
        } else {
            if ($('.mijnoffertesblokken').length <= 20) {
                var url = '/get/contracten?page=2';
            }
        }

    }
    if (Object.keys(get).length) {
        url += '&color=' + get.type;
    }

    if ($('.contracten')[0]) {
        url += '&allcon=true';
    }

    if ($('.all-off')[0]) {
        url += '&alloff=true';
    }


	$.ajax({
        url: url,
        type: 'GET',
        contentType: "application/json; charset=utf-8",
        dataType:"json",
		async: false,
        success: function (data) {

			var next = data.next_page_url;
			window.globalVar = next;

			var html = "";
			$.each(data.data, function(key, value){

                if (value.color == 'c_hellend') {
                    title = 'Hellend dak';
                } else if (value.color == 'c_plat') {
                    title = 'Plat dak';
                } else if (value.color == 'c_combi') {
                    title = 'Combinatie';
                } else if (value.color == 'c_ontmossen') {
                    title = 'Ontmossen';
                } else if (value.color == 'c_gevel') {
                    title = 'Gevelwerken';
                } else if (value.color == 'c_zolder') {
                    title = 'Zolder';
                } else if (value.color == 'c_blanco') {
                    title = 'Blanco';
                }

                var all_off = all_cont = false;

                if ($('.all-contract')[0]) {
                    var all_cont = true;
                }

                if ($('.all-off')[0]) {
                    var all_off = true;
                }



                var date = new Date(value.created_at);
                date =  date.getDate() +  '/' +(date.getMonth() + 1) + '/' +  date.getFullYear();
                if (all_cont) {
                    $('.admin-blocks').append('<div class="mijnoffertesblokken ' + value.color +'"><a class="tagtoofferte" href="/offerte/' + value.id + '"></a><div class="headerblockinfo"><h3>' + title + ' </h3><a href="/downloaden/' + value.path + '" class="offertedownloadbutton" ' + ((value.updated_at < '2017-2-19 00:00:00')? 'class="full-width"' : '') +  'href="/offerte/downloaden/' + value.path + '"><p class="colorp"><span><i class="fa fa-pencil" aria-hidden="true"></i> ' + value.user.name + ' </a></span></p></div><div class="informationofferte"><div class="left"><p><i class="fa fa-user" aria-hidden="true"></i>' + value.klant.voornaam + ' ' +  value.klant.achternaam + '</p><p><i class="fa fa-phone" aria-hidden="true"></i>' + value.klant.gsm + '</p></div><div class="right"><p><i class="fa fa-eur" aria-hidden="true"></i>' + value.total + '</p><p><i class="fa fa-calendar" aria-hidden="true"></i>' + date + '</p></div><div class="clear"></div><div class="full"><p><i class="fa fa-map-marker" aria-hidden="true"></i>' + value.klant.adres + ',' + value.klant.postcode + ' ' + value.klant.plaats + '</p></div></div><div style="height: 25px;"></div><div class="downloadbuttons"><a href="/offerte/downloaden/' + value.path + '"><i class="fa fa-arrow-circle-down" aria-hidden="true"></i> Offerte</a><a href="/werkfiche/downloaden/' + value.path + '"><i class="fa fa-arrow-circle-down" aria-hidden="true"></i> Werkfiche</a></div></div>');
                    equalheight('.mijnoffertesblokken');
                } else if (all_off) {
                    $('.admin-blocks').append('<div class="mijnoffertesblokken ' + value.color +'"><a class="tagtoofferte" href="/offerte/' + value.id + '"></a><div class="headerblockinfo"><h3>' + title + ' </h3><a href="/downloaden/' + value.path + '" class="offertedownloadbutton" ' + ((value.updated_at < '2017-12-19 00:00:00')? 'class="full-width"' : '') +  'href="/offerte/downloaden/' + value.path + '"><p class="colorp"><span><i class="fa fa-pencil" aria-hidden="true"></i> ' + value.user.name + ' </a></span></p></div><div class="informationofferte"><div class="left"><p><i class="fa fa-user" aria-hidden="true"></i>' + value.klant.voornaam + ' ' +  value.klant.achternaam + '</p><p><i class="fa fa-phone" aria-hidden="true"></i>' + value.klant.gsm + '</p></div><div class="right"><p><i class="fa fa-eur" aria-hidden="true"></i>' + value.total + '</p><p><i class="fa fa-calendar" aria-hidden="true"></i>' + date + '</p></div><div class="clear"></div><div class="full"><p><i class="fa fa-map-marker" aria-hidden="true"></i>' + value.klant.adres + ',' + value.klant.postcode + ' ' + value.klant.plaats + '</p></div></div><div style="height: 25px;"></div><div class="downloadbuttons"><a href="/offerte/downloaden/' + value.path + '"><i class="fa fa-arrow-circle-down" aria-hidden="true"></i> Offerte</a><a href="/werkfiche/downloaden/' + value.path + '"><i class="fa fa-arrow-circle-down" aria-hidden="true"></i> Werkfiche</a></div></div>');
                    equalheight('.mijnoffertesblokken');
                } else if (offerte) {
                    $('.admin-blocks').append('<div class="mijnoffertesblokken ' + value.color +'"><a class="tagtoofferte" href="/offerte/' + value.id + '"></a><div class="headerblockinfo"><h3>' + title + ' </h3><a href="/downloaden/' + value.path + '" class="offertedownloadbutton" ' + ((value.updated_at < '2017-12-19 00:00:00')? 'class="full-width"' : '') +  'href="/offerte/downloaden/' + value.path + '"><i class="fa fa-arrow-circle-down" aria-hidden="true"></i> Offerte</a></div><div class="informationofferte"><div class="left"><p><i class="fa fa-user" aria-hidden="true"></i>' + value.klant.voornaam + ' ' +  value.klant.achternaam + '</p><p><i class="fa fa-phone" aria-hidden="true"></i>' + value.klant.gsm + '</p></div><div class="right"><p><i class="fa fa-eur" aria-hidden="true"></i>' + value.total + '</p><p><i class="fa fa-calendar" aria-hidden="true"></i>' + date + '</p></div><div class="clear"></div><div class="full"><p><i class="fa fa-map-marker" aria-hidden="true"></i>' + value.klant.adres + ',' + value.klant.postcode + ' ' + value.klant.plaats + '</p></div></div><div style="height: 25px;"></div><div class="buttonsofferteuitkomst"><a class="vervolgAfspraak' + ((value.vervolg)? ' followup-active' : '') + '" href="/offerte/' + value.id + '/vervolg"><i class="fa fa-calendar" aria-hidden="true"></i> 2<sup>nd</sup> app.</a><a class="topOppertunitie' + ((value.top)? ' top-active' : '') + '" href="/top/offerte/' + value.id + '"><i class="fa fa-star" aria-hidden="true"></i> Top</a><a class="lostOfferte" href="/offerte/status/nvt/' + value.klant.id + '/' + value.id + '"><i class="fa fa-times" aria-hidden="true"></i> Lost</a><a class="Signedofferte" href="/offerte/status/getekend/' + value.klant.id + '/' + value.id + '"><i class="fa fa-check" aria-hidden="true"></i> Signed</a></div></div>');
                    equalheight('.mijnoffertesblokken');
                } else {
                    $('.admin-blocks').append('<div class="mijnoffertesblokken ' + value.color +'"><a class="tagtoofferte" href="/offerte/' + value.id + '"></a><div class="headerblockinfo"><h3>' + title + ' </h3><a href="/downloaden/' + value.path + '" class="offertedownloadbutton" ' + ((value.updated_at < '2017-12-19 00:00:00')? 'class="full-width"' : '') +  'href="/offerte/downloaden/' + value.path + '"><i class="fa fa-arrow-circle-down" aria-hidden="true"></i> Offerte</a></div><div class="informationofferte"><div class="left"><p><i class="fa fa-user" aria-hidden="true"></i>' + value.klant.voornaam + ' ' +  value.klant.achternaam + '</p><p><i class="fa fa-phone" aria-hidden="true"></i>' + value.klant.gsm + '</p></div><div class="right"><p><i class="fa fa-eur" aria-hidden="true"></i>' + value.total + '</p><p><i class="fa fa-calendar" aria-hidden="true"></i>' + date + '</p></div><div class="clear"></div><div class="full"><p><i class="fa fa-map-marker" aria-hidden="true"></i>' + value.klant.adres + ',' + value.klant.postcode + ' ' + value.klant.plaats + '</p></div></div><div style="height: 25px;"></div><div class="downloadbuttons"><a href="/offerte/downloaden/' + value.path + '"><i class="fa fa-arrow-circle-down" aria-hidden="true"></i> Offerte</a><a href="/werkfiche/downloaden/' + value.path + '"><i class="fa fa-arrow-circle-down" aria-hidden="true"></i> Werkfiche</a></div></div>');
                    equalheight('.mijnoffertesblokken');
                }
			});
        }
    });
}

function getQueryParams(qs) {
    qs = qs.split("+").join(" ");
    var params = {},
        tokens,
        re = /[?&]?([^=]+)=([^&]*)/g;

    while (tokens = re.exec(qs)) {
        params[decodeURIComponent(tokens[1])]
            = decodeURIComponent(tokens[2]);
    }

    return params;
}

function searchLazy(that, done, type, offerte, all)
{
    if (!type) {
        type = 'undefined';
    }
    var val = $(that).val();
    if (!val) {
        val = "false";
    }
    var get = getQueryParams(document.location.search);

    // if (Object.keys(get).length) {
    //     url += '/search/lazy/' + val + '?color=' + get.type;
    // }

    var all_off = all_cont = false;

    if (offerte && !all) {
        var url = '/search/lazy/' + done + '/' + val + '/' + type;
    } else if (!offerte && !all) {
        var url = '/search/contract/' + done + '/' + val + '/' + type;
    } else if (offerte && all) {
        var all_off = true;
        var url = '/search/lazy/' + done + '/' + val + '/' + type + '/all';
    } else if (!offerte && all) {
        var all_cont = true;
        var url = '/search/contract/' + done + '/' + val + '/' + type + '/all';
    }


    $.ajax({
        url: url,
        type: 'GET',
        contentType: "application/json; charset=utf-8",
        dataType:"json",
		async: false,
        success: function (data) {
            console.log(data.data);
            $('.admin-blocks').empty();

			var next = data.next_page_url;
			window.globalVar = next;

			var html = "";
			$.each(data.data, function(key, value){

                if (value.color == 'c_hellend') {
                    title = 'Hellend dak';
                } else if (value.color == 'c_plat') {
                    title = 'Plat dak';
                } else if (value.color == 'c_combi') {
                    title = 'Combinatie';
                } else if (value.color == 'c_ontmossen') {
                    title = 'Ontmossen';
                } else if (value.color == 'c_gevel') {
                    title = 'Gevelwerken';
                } else if (value.color == 'c_zolder') {
                    title = 'Zolder';
                } else if (value.color == 'c_blanco') {
                    title = 'Blanco';
                }

                var date = new Date(value.created_at);
                date =  date.getDate() +  '/' +(date.getMonth() + 1) + '/' +  date.getFullYear();
                if (all_cont) {
                    $('.admin-blocks').append('<div class="mijnoffertesblokken ' + value.color +'"><a class="tagtoofferte" href="/offerte/' + value.id + '"></a><div class="headerblockinfo"><h3>' + title + ' </h3><a href="/downloaden/' + value.path + '" class="offertedownloadbutton" ' + ((value.updated_at < '2017-12-19 00:00:00')? 'class="full-width"' : '') +  'href="/offerte/downloaden/' + value.path + '"><p class="colorp"><span><i class="fa fa-pencil" aria-hidden="true"></i> ' + value.user.name + ' </a></span></p></div><div class="informationofferte"><div class="left"><p><i class="fa fa-user" aria-hidden="true"></i>' + value.klant.voornaam + ' ' +  value.klant.achternaam + '</p><p><i class="fa fa-phone" aria-hidden="true"></i>' + value.klant.gsm + '</p></div><div class="right"><p><i class="fa fa-eur" aria-hidden="true"></i>' + value.total.toFixed(2) + '</p><p><i class="fa fa-calendar" aria-hidden="true"></i>' + date + '</p></div><div class="clear"></div><div class="full"><p><i class="fa fa-map-marker" aria-hidden="true"></i>' + value.klant.adres + ',' + value.klant.postcode + ' ' + value.klant.plaats + '</p></div></div><div style="height: 25px;"></div><div class="downloadbuttons"><a href="/offerte/downloaden/' + value.path + '"><i class="fa fa-arrow-circle-down" aria-hidden="true"></i> Offerte</a><a href="/werkfiche/downloaden/' + value.path + '"><i class="fa fa-arrow-circle-down" aria-hidden="true"></i> Werkfiche</a></div></div>');
                    equalheight('.mijnoffertesblokken');
                } else if (all_off) {
                    $('.admin-blocks').append('<div class="mijnoffertesblokken ' + value.color +'"><a class="tagtoofferte" href="/offerte/' + value.id + '"></a><div class="headerblockinfo"><h3>' + title + ' </h3><a href="/downloaden/' + value.path + '" class="offertedownloadbutton" ' + ((value.updated_at < '2017-12-19 00:00:00')? 'class="full-width"' : '') +  'href="/offerte/downloaden/' + value.path + '"><p class="colorp"><span><i class="fa fa-pencil" aria-hidden="true"></i> ' + value.user.name + ' </a></span></p></div><div class="informationofferte"><div class="left"><p><i class="fa fa-user" aria-hidden="true"></i>' + value.klant.voornaam + ' ' +  value.klant.achternaam + '</p><p><i class="fa fa-phone" aria-hidden="true"></i>' + value.klant.gsm + '</p></div><div class="right"><p><i class="fa fa-eur" aria-hidden="true"></i>' + value.total.toFixed(2) + '</p><p><i class="fa fa-calendar" aria-hidden="true"></i>' + date + '</p></div><div class="clear"></div><div class="full"><p><i class="fa fa-map-marker" aria-hidden="true"></i>' + value.klant.adres + ',' + value.klant.postcode + ' ' + value.klant.plaats + '</p></div></div><div style="height: 25px;"></div><div class="downloadbuttons"><a href="/offerte/downloaden/' + value.path + '"><i class="fa fa-arrow-circle-down" aria-hidden="true"></i> Offerte</a><a href="/werkfiche/downloaden/' + value.path + '"><i class="fa fa-arrow-circle-down" aria-hidden="true"></i> Werkfiche</a></div></div>');
                    equalheight('.mijnoffertesblokken');
                } else if (offerte) {
                    $('.admin-blocks').append('<div class="mijnoffertesblokken ' + value.color +'"><a class="tagtoofferte" href="/offerte/' + value.id + '"></a><div class="headerblockinfo"><h3>' + title + ' </h3><a href="/downloaden/' + value.path + '" class="offertedownloadbutton" ' + ((value.updated_at < '2017-12-19 00:00:00')? 'class="full-width"' : '') +  'href="/offerte/downloaden/' + value.path + '"><i class="fa fa-arrow-circle-down" aria-hidden="true"></i> Offerte</a></div><div class="informationofferte"><div class="left"><p><i class="fa fa-user" aria-hidden="true"></i>' + value.klant.voornaam + ' ' +  value.klant.achternaam + '</p><p><i class="fa fa-phone" aria-hidden="true"></i>' + value.klant.gsm + '</p></div><div class="right"><p><i class="fa fa-eur" aria-hidden="true"></i>' + value.total.toFixed(2) + '</p><p><i class="fa fa-calendar" aria-hidden="true"></i>' + date + '</p></div><div class="clear"></div><div class="full"><p><i class="fa fa-map-marker" aria-hidden="true"></i>' + value.klant.adres + ',' + value.klant.postcode + ' ' + value.klant.plaats + '</p></div></div><div style="height: 25px;"></div><div class="buttonsofferteuitkomst"><a class="vervolgAfspraak' + ((value.vervolg)? ' followup-active' : '') + '" href="/offerte/' + value.id + '/vervolg"><i class="fa fa-calendar" aria-hidden="true"></i> 2<sup>nd</sup> app.</a><a class="topOppertunitie' + ((value.top)? ' top-active' : '') + '" href="/top/offerte/' + value.id + '"><i class="fa fa-star" aria-hidden="true"></i> Top</a><a class="lostOfferte" href="/offerte/status/nvt/' + value.klant.id + '/' + value.id + '"><i class="fa fa-times" aria-hidden="true"></i> Lost</a><a class="Signedofferte" href="/offerte/status/getekend/' + value.klant.id + '/' + value.id + '"><i class="fa fa-check" aria-hidden="true"></i> Signed</a></div></div>');
                    equalheight('.mijnoffertesblokken');
                } else {
                    $('.admin-blocks').append('<div class="mijnoffertesblokken ' + value.color +'"><a class="tagtoofferte" href="/offerte/' + value.id + '"></a><div class="headerblockinfo"><h3>' + title + ' </h3><a href="/downloaden/' + value.path + '" class="offertedownloadbutton" ' + ((value.updated_at < '2017-12-19 00:00:00')? 'class="full-width"' : '') +  'href="/offerte/downloaden/' + value.path + '"><i class="fa fa-arrow-circle-down" aria-hidden="true"></i> Offerte</a></div><div class="informationofferte"><div class="left"><p><i class="fa fa-user" aria-hidden="true"></i>' + value.klant.voornaam + ' ' +  value.klant.achternaam + '</p><p><i class="fa fa-phone" aria-hidden="true"></i>' + value.klant.gsm + '</p></div><div class="right"><p><i class="fa fa-eur" aria-hidden="true"></i>' + value.total.toFixed(2) + '</p><p><i class="fa fa-calendar" aria-hidden="true"></i>' + date + '</p></div><div class="clear"></div><div class="full"><p><i class="fa fa-map-marker" aria-hidden="true"></i>' + value.klant.adres + ',' + value.klant.postcode + ' ' + value.klant.plaats + '</p></div></div><div style="height: 25px;"></div><div class="downloadbuttons"><a href="/offerte/downloaden/' + value.path + '"><i class="fa fa-arrow-circle-down" aria-hidden="true"></i> Offerte</a><a href="/werkfiche/downloaden/' + value.path + '"><i class="fa fa-arrow-circle-down" aria-hidden="true"></i> Werkfiche</a></div></div>');
                    equalheight('.mijnoffertesblokken');
                }
			});
        }
    });
}

function filterTop(on, type, offerte)
{
    if (offerte){
        var url = '/filter/top/' + on + '/' + type;
    } else {
        var url = '/filter/top/contract/' + on + '/' + type;
    }

    $.ajax({
        url: url,
        type: 'GET',
        contentType: "application/json; charset=utf-8",
        dataType:"json",
		async: false,
        success: function (data) {
            $('.admin-blocks').empty();

			var next = data.next_page_url;
			window.globalVar = next;

			var html = "";
			$.each(data.data, function(key, value){

                if (value.color == 'c_hellend') {
                    title = 'Hellend dak';
                } else if (value.color == 'c_plat') {
                    title = 'Plat dak';
                } else if (value.color == 'c_combi') {
                    title = 'Combinatie';
                } else if (value.color == 'c_ontmossen') {
                    title = 'Ontmossen';
                } else if (value.color == 'c_gevel') {
                    title = 'Gevelwerken';
                } else if (value.color == 'c_zolder') {
                    title = 'Zolder';
                } else if (value.color == 'c_blanco') {
                    title = 'Blanco';
                }

                var date = new Date(value.created_at);
                date =  date.getDate() +  '/' +(date.getMonth() + 1) + '/' +  date.getFullYear();

                if (offerte) {
                    $('.admin-blocks').append('<div class="mijnoffertesblokken ' + value.color +'"><a class="tagtoofferte" href="/offerte/' + value.id + '"></a><div class="headerblockinfo"><h3>' + title + ' </h3><a href="/downloaden/' + value.path + '" class="offertedownloadbutton" ' + ((value.updated_at < '2017-12-19 00:00:00')? 'class="full-width"' : '') +  'href="/offerte/downloaden/' + value.path + '"><i class="fa fa-arrow-circle-down" aria-hidden="true"></i> Offerte</a></div><div class="informationofferte"><div class="left"><p><i class="fa fa-user" aria-hidden="true"></i>' + value.klant.voornaam + ' ' +  value.klant.achternaam + '</p><p><i class="fa fa-phone" aria-hidden="true"></i>' + value.klant.gsm + '</p></div><div class="right"><p><i class="fa fa-eur" aria-hidden="true"></i>' + value.total + '</p><p><i class="fa fa-calendar" aria-hidden="true"></i>' + date + '</p></div><div class="clear"></div><div class="full"><p><i class="fa fa-map-marker" aria-hidden="true"></i>' + value.klant.adres + ',' + value.klant.postcode + ' ' + value.klant.plaats + '</p></div></div><div style="height: 25px;"></div><div class="buttonsofferteuitkomst"><a class="vervolgAfspraak' + ((value.vervolg)? ' followup-active' : '') + '" href="/offerte/' + value.id + '/vervolg"><i class="fa fa-calendar" aria-hidden="true"></i> 2<sup>nd</sup> app.</a><a class="topOppertunitie' + ((value.top)? ' top-active' : '') + '" href="/top/offerte/' + value.id + '"><i class="fa fa-star" aria-hidden="true"></i> Top</a><a class="lostOfferte" href="/offerte/status/nvt/' + value.klant.id + '/' + value.id + '"><i class="fa fa-times" aria-hidden="true"></i> Lost</a><a class="Signedofferte" href="/offerte/status/getekend/' + value.klant.id + '/' + value.id + '"><i class="fa fa-check" aria-hidden="true"></i> Signed</a></div></div>');
                    equalheight('.mijnoffertesblokken');
                } else {
                    $('.admin-blocks').append('<div class="mijnoffertesblokken ' + value.color +'"><a class="tagtoofferte" href="/offerte/' + value.id + '"></a><div class="headerblockinfo"><h3>' + title + ' </h3><a href="/downloaden/' + value.path + '" class="offertedownloadbutton" ' + ((value.updated_at < '2017-12-19 00:00:00')? 'class="full-width"' : '') +  'href="/offerte/downloaden/' + value.path + '"><i class="fa fa-arrow-circle-down" aria-hidden="true"></i> Offerte</a></div><div class="informationofferte"><div class="left"><p><i class="fa fa-user" aria-hidden="true"></i>' + value.klant.voornaam + ' ' +  value.klant.achternaam + '</p><p><i class="fa fa-phone" aria-hidden="true"></i>' + value.klant.gsm + '</p></div><div class="right"><p><i class="fa fa-eur" aria-hidden="true"></i>' + value.total + '</p><p><i class="fa fa-calendar" aria-hidden="true"></i>' + date + '</p></div><div class="clear"></div><div class="full"><p><i class="fa fa-map-marker" aria-hidden="true"></i>' + value.klant.adres + ',' + value.klant.postcode + ' ' + value.klant.plaats + '</p></div></div><div style="height: 25px;"></div><div class="downloadbuttons"><a href="/offerte/downloaden/' + value.path + '"><i class="fa fa-arrow-circle-down" aria-hidden="true"></i> Offerte</a><a href="/werkfiche/downloaden/' + value.path + '"><i class="fa fa-arrow-circle-down" aria-hidden="true"></i> Werkfiche</a></div></div>');
                    equalheight('.mijnoffertesblokken');
                }
			});
        }
    });
}
