Dropzone.autoDiscover = false;

$(window).load(function(){
    $('.dashboard-home').fadeIn();
    $('.grid').packery({
      itemSelector: '.grid-item',
      gutter: 0
    });

    // $('.metric').keydown().keypress().keyup().blur();

    var typingTimer;                //timer identifier
    var doneTypingInterval = 1000;  //time in ms, 5 second for example
    var $input = $('.fancy-search');

    $input.on('keyup', function () {
        clearTimeout(typingTimer);
        typingTimer = setTimeout(doneTyping, doneTypingInterval);
    });

    //on keydown, clear the countdown
    $input.on('keydown', function () {
        clearTimeout(typingTimer);
    });

    // $(document).on('keyup','.fancy-search', function(evt){
    function doneTyping(){
        var type = $('[name="type"]').val();

        if ($('.contracten')[0]) {
            if ($('.all-contract')[0]) {
                searchLazy($('.fancy-search'), 1, type, false, true);
            } else {
                searchLazy($('.fancy-search'), 1, type, false);
            }
        } else {
            if ($('.all-off')[0]) {
                searchLazy($('.fancy-search'), 0, type, true, true);
            } else {
                searchLazy($('.fancy-search'), 0, type, true);
            }
        }
    };

    var size = $('input[name="project_size"]').val();

    $('input[name="STO"]').click();
    $('input[name="STO_hoeveelheid"]').val(size).keydown().keypress().keyup().blur();

});


$('document').ready(function(){

    // $(window).scroll(function() {
	// 	if($(window).scrollTop() + $(window).height() == $(document).height()) {
    //         if ($('.contracten')[0]) {
    //             addNextPage(false);
    //         } else {
    //             addNextPage(true);
    //         }
	// 	}
	// });

    $(document).on('click', '.checkbox-extra-c input[type="checkbox"]', function(event){
        if ($(this).is(':checked')) {
            $('[name="extra_amount"]').show();
        } else {
            $('[name="extra_amount"]').hide();
        }
    });

    $(document).on('keyup', '[name="extra_amount"]', function(event){
        console.log($(this).val());
        if ($(this).val() > 0 && $(this).val() <= 10) {
            $('.x-c-e').hide();
        } else {
            if ($(this).val() != "" && $.isNumeric($(this).val())) {
                $('.x-c-e').show();
            }
        }
    });

    $('.velux-afwerken').on('click', function(){
        if ($(this).val() == "Ja") {
            $('[trigger-id="AFW"]').click();
        }
    });

    $('')

    $('[trigger-id="AGO"]').parent().find('p').append('<br>(Alleen bij bestaande veluxen)');
    $('[trigger-id="AFV"], [trigger-id="ASB"]').parent().find('p').append('<br>(1 container per 100m2)');

    $('.toggle-jaar-maand a').on('click', function(){
        if ($(this).hasClass('month')) {
            $('.omzetvsdoeljaar').hide();
            $('.omzetvsdoel').show();
        } else {
            $('.omzetvsdoeljaar').show();
            $('.omzetvsdoel').hide();
        }
    });

    $('[for="afvoerbuis_vierkant"]').on('click', function(){
        if ($('input[toggle="afvoerbuis_vierkant"]').is(':checked')) {
            $('input[name="ZAF_vierkant"]').val(0).keydown().keypress().keyup().blur();
        }
    });

    $('[for="hanggoot_vierkant"]').on('click', function(){
        if ($('input[toggle="hanggoot_vierkant"]').is(':checked')) {
            $('input[name="ZHG_vierkant"]').val(0).keydown().keypress().keyup().blur();
        }
    });

    $('[for="afvoerbuis_rond"]').on('click', function(){
        if ($('input[toggle="afvoerbuis_rond"]').is(':checked')) {
            $('input[name="ZAF_rond"]').val(0).keydown().keypress().keyup().blur();
        }
    });

    $('[for="hanggoot_rond"]').on('click', function(){
        if ($('input[toggle="hanggoot_rond"]').is(':checked')) {
            $('input[name="ZHG_rond"]').val(0).keydown().keypress().keyup().blur();
        }
    });

    //FILTER DASHBOARD EURO EN DATE

    $('.filtersortingpipe .sorteuro').on('click', function(evt){
        if ($('.filtersortingpipe .sortdate').hasClass('active')) {
            $('.filtersortingpipe .sortdate').removeClass('active');
        }
        $(this).toggleClass('active');
        evt.stopPropagation();
        evt.preventDefault();
    });
    $('.filtersortingpipe .sortdate').on('click', function(evt){
        if ($('.filtersortingpipe .sorteuro').hasClass('active')) {
            $('.filtersortingpipe .sorteuro').removeClass('active');
        }
        $(this).toggleClass('active');
        evt.stopPropagation();
        evt.preventDefault();
    });

    $('.filtersortingopp .sorteuro').on('click', function(evt){
        if ($('.filtersortingopp .sortdate').hasClass('active')) {
            $('.filtersortingopp .sortdate').removeClass('active');
        }
        $(this).toggleClass('active');
        evt.stopPropagation();
        evt.preventDefault();
    });
    $('.filtersortingopp .sortdate').on('click', function(evt){
        if ($('.filtersortingopp .sorteuro').hasClass('active')) {
            $('.filtersortingopp .sorteuro').removeClass('active');
        }
        $(this).toggleClass('active');
        evt.stopPropagation();
        evt.preventDefault();
    });

    $('.filtersortingomz .sorteuro').on('click', function(evt){
        if ($('.filtersortingomz .sortdate').hasClass('active')) {
            $('.filtersortingomz .sortdate').removeClass('active');
        }
        $(this).toggleClass('active');
        evt.stopPropagation();
        evt.preventDefault();
    });
    $('.filtersortingomz .sortdate').on('click', function(evt){
        if ($('.filtersortingomz .sorteuro').hasClass('active')) {
            $('.filtersortingomz .sorteuro').removeClass('active');
        }
        $(this).toggleClass('active');
        evt.stopPropagation();
        evt.preventDefault();
    });

    //EINDE

    // Filter omzet
    $('.filter-db input').on('click', function(){
        var month = $('[name="filter-value-month"]').val();
        var year = $('[name="filter-value-year"]').val();
        var trigger = $(this).parent().attr('filter');
        var user_id = $('[name="user_selected"]').val();

        if (trigger == 'omzet') {
            if ($(this).hasClass('sorteuro')) {
                if ($(this).hasClass('active')) {
                    var url = '/filter/' + user_id + '/omzet/amount/asc/' + month + '/' + year;
                } else {
                    var url = '/filter/' + user_id + '/omzet/amount/desc/' + month + '/' + year;
                }
            } else if ($(this).hasClass('sortdate')) {
                if ($(this).hasClass('active')) {
                    var url = '/filter/' + user_id + '/omzet/date/asc/' + month + '/' + year;
                } else {
                    var url = '/filter/' + user_id + '/omzet/date/desc/' + month + '/' + year;
                }
            }

            var table = '.op-omz';
        } else if(trigger == "opportunitie") {
            if ($(this).hasClass('sorteuro')) {
                if ($(this).hasClass('active')) {
                    var url = '/filter/' + user_id + '/top/amount/asc/' + month + '/' + year;
                } else {
                    var url = '/filter/' + user_id + '/top/amount/desc/' + month + '/' + year;
                }
            } else if ($(this).hasClass('sortdate')) {
                if ($(this).hasClass('active')) {
                    var url = '/filter/' + user_id + '/top/date/asc/' + month + '/' + year;
                } else {
                    var url = '/filter/' + user_id + '/top/date/desc/' + month + '/' + year;
                }
            }

            var table = '.op-top';
        } else if(trigger == "pipeline") {
            var table = '.op-pipeline';
            var type = $('.active-filter').attr('for');

            if ($(this).hasClass('sorteuro')) {
                if ($(this).hasClass('active')) {
                    var url = '/filter/' + user_id + '/pipeline/amount/asc/' + month + '/' + year + '/' + type;
                } else {
                    var url = '/filter/' + user_id + '/pipeline/amount/desc/' + month + '/' + year + '/' + type;
                }
            } else if ($(this).hasClass('sortdate')) {
                if ($(this).hasClass('active')) {
                    var url = '/filter/' + user_id + '/pipeline/date/asc/' + month + '/' + year + '/' + type;
                } else {
                    var url = '/filter/' + user_id + '/pipeline/date/desc/' + month + '/' + year + '/' + type;
                }
            }
        }


        filterDb(url, table);
    });

    function filterDb(url, table) {
        $.ajax({
            url: url,
            type: 'GET',
            contentType: "application/json; charset=utf-8",
            dataType:"json",
            async: false,
            success: function (data) {
                $(table).empty();
                var html = "";
                var total = 0;

                $.each(data, function(key, value){
                    var date = new Date(value.created_at);
                    var d = date.getDate();
                    var m = date.toLocaleString('nl-NL', { month: "short" });
                    total += value.total;
                    var html = '<li class="' + value.color + '"><a class="fulllinktoofferte" href="/offerte/' + value.id + '"></a><div class="datecirckle"><p><span class="dag">' + d + '</span><span class="maand">' + m + '</span></p></div><div class="informationperson"><h4>' + value.klant.achternaam + '</h4><p>' + value.klant.postcode + ' ' + value.klant.plaats + '</p></div><div class="priceofferte">€ ' + (value.total).toLocaleString('nl-NL', {minimumFractionDigits: 2}) + '</div></li>';
                    $(table).append(html);
                });
            }
        });
    }


    $('[name="ranking"]').on('change', function(){
        var val = $(this).val().toLowerCase();
        var month = $('[name="filter-value-month"]').val();
        var year = $('[name="filter-value-year"]').val();

        if (val == "hit rate") {
            var url = '/filter/ranking/' + month + '/' + year;
            var eenheid = '%';
            $('.listofnames2, .topRanking2').hide();
            $('.topRanking').show();
        } else if (val == "omzet") {
            var url = '/filter/omzet/' + month + '/' + year;
            var eenheid = '€';
            $('.listofnames2, .topRanking2').hide();
            $('.topRanking').show();
        } else if (val == "one shot") {
            var url = '/filter/shot/' + month + '/' + year;
            var eenheid = '%';
            $('.listofnames2, .topRanking2').hide();
            $('.topRanking').show();
        } else {
            $('.listofnames').empty();
            $('.topRanking').hide();
            $('.listofnames2, .topRanking2').show();
            return false;
        }

        $.ajax({
            url: url,
            type: 'GET',
            async: false,
            contentType: "application/json; charset=utf-8",
            dataType:"json",
            success: function (data){
                $('.listofnames').empty();
                if (data[0]) {
                    $('.verkoperraking.first').css('background-image', 'url(/images/verkopers/' + data[0].img + '.png)');
                } else {
                    $('.topRanking .verkoperraking.first').css('background-image', 'url(/images/verkopers/.png)');
                }
                if (data[1]) {
                    $('.verkoperraking.second').css('background-image', 'url(/images/verkopers/' + data[1].img + '.png)');
                } else {
                    $('.topRanking .verkoperraking.second').css('background-image', 'url(/images/verkopers/.png)');
                }
                if (data[2]) {
                    $('.verkoperraking.third').css('background-image', 'url(/images/verkopers/' + data[2].img + '.png)');
                } else {
                    $('.topRanking .verkoperraking.third').css('background-image', 'url(/images/verkopers/.png)');
                }

                $.each(data, function(key, value){
                    var html = '<p><span class="rankingnumber">' + (key+1) + '.</span> <span class="nameverkoper">' + value.user + '</span> <span class="omzetverkoper">' + ((value.total)).toLocaleString() + eenheid  +'</span></p>';
    				$('.listofnames').append(html);
    			});

            }
        });

    });

    $('.control.control--checkbox-contract').on('click', function(evt){
        $(this).toggleClass('active');
        var type = $('[name="type"]').val();
        filterTop($(this).hasClass('active'), type);
        evt.stopPropagation();
        evt.preventDefault();
    });

    $('.control.control--checkbox').on('click', function(evt){
       $(this).toggleClass('active');
       var type = $('[name="type"]').val();
       filterTop($(this).hasClass('active'), type, true);
       evt.stopPropagation();
       evt.preventDefault();
   });

    $('.rightfilter label').on('click', function(){
        if ($(this).hasClass('active-filter')) {
            $('.active-filter').removeClass('active-filter');
        } else {
            $('.active-filter').removeClass('active-filter');
            $(this).addClass('active-filter');
        }

        filterPipeline($(this));
    });

    $('.verkoper-select').on('change', function(){
        $(this).closest('form').submit();
    });

    $('.periode, .update-filter').on('change', function(){
        $(this).closest('form').submit();
    });

    // One shot
    $(document).on('click', '.Signedofferte', function(event){
    // $('.Signedofferte').on('click', function(event){
        event.preventDefault();

        $('.one-shot-question').show();
        $('.overlay').show();
        var link = $(this).attr('href');

        $('.left-btn').attr('href', link + '/1');
        $('.right-btn').attr('href', link + '/0');
    });

    $('.overlay').on('click', function(){
        $('.one-shot-question').hide();
        $('.overlay').hide();
    });


    //top left menu
    function topheader(){
        var heighttopmenu = $('.topnavigation').outerHeight();
        var windowheight = window.innerHeight;
        $('#navigation-left').css("top", heighttopmenu);
        $('.content-wrapper-right').css("top", heighttopmenu);
        $('.wrapperstratcontent').css("height", windowheight - heighttopmenu - 120);
    }
    topheader();

    //TRIGGER RESPONSIVE MENU LEFT
    $('#trigger').click(function(){
        if($('body').hasClass('openmenu')){
            $('body').removeClass('openmenu');
            $('#navigation-left ul.menu').slideUp();
        }else{
            $('body').addClass('openmenu');
            $('#navigation-left ul.menu').slideDown();
        }
    });

    //SUBMENU
    $('#navigation-left .menu li.haschildren > a').on('click', function(event){
        event.preventDefault();
        if($(this).hasClass('open')){
            $(this).removeClass('open');
            $(this).parent().find('.submenu').slideUp();
        }else{
            $(this).addClass('open');
            $(this).parent().find('.submenu').slideDown();
        }
    });

    //Open top menu user settings
    $('.topnavigation .userinlog .menuUserLogout li.usermenu > a').on('click', function(event){
        event.preventDefault();
        if($(this).hasClass('usermenu')){
            $(this).removeClass('usermenu');
            $(this).parent().find('.settingsusermenu').slideUp();
        }else{
            $(this).addClass('usermenu');
            $(this).parent().find('.settingsusermenu').slideDown();
        }
    });

    // packery dashboardContent
    var $grid = $('.grid').packery({
      itemSelector: '.grid-item',
      gutter: 0
    });

    $grid.on('layoutComplete', function(){
        //chart 2
        var quantitydonut = parseInt($('.oneshotvsdoel .quantitydonut').html());
        var totaldonut = parseInt($('.oneshotvsdoel .totaldonut').html());
        var totalpercentage = ((quantitydonut/totaldonut)*100).toFixed(0);

        if (totaldonut == 0) {
            var totalpercentage = 0;
        }
        var nogtehalen = totaldonut - quantitydonut;
        var totaalmaaltwee = totaldonut * 2;

        $('.oneshotvsdoel p.percentage').text(totalpercentage + '%');

        new Chartist.Pie('#oneshotvsdoel', {
            series: [quantitydonut, nogtehalen]
        }, {
            donut: true,
            donutWidth: 25,
            donutSolid: true,
            startAngle: 270,
            total: totaalmaaltwee,
            showLabel: true
        });

        // //chart 3
        // var quantitydonut = parseInt($('.gemdoorlooptijd .quantitydonut').html());
        // var totaldonut = parseInt($('.gemdoorlooptijd .totaldonut').html());
        // var totalpercentage = ((quantitydonut/totaldonut)*100).toFixed(0);
        // var nogtehalen = totaldonut - quantitydonut;
        //
        // $('.gemdoorlooptijd p.percentagegem').text(totalpercentage + '%');
        //
        // new Chartist.Pie('#gemdoorlooptijd', {
        //     series: [quantitydonut, nogtehalen]
        // }, {
        //     donut: true,
        //     donutWidth: 12,
        //     donutSolid: true,
        //     startAngle: 200,
        //     total: totaldonut,
        //     showLabel: false
        // });

        //chart 4
        var quantitydonut = parseInt($('.omzetvsdoel .quantitydonut').html());
        var totaldonut = parseInt($('.omzetvsdoel .totaldonut').html());
        var totalpercentage = ((quantitydonut/totaldonut)*100).toFixed(0);
        var nogtehalen = totaldonut - quantitydonut;
        var totaalmaaltwee = totaldonut * 2;

        $('.omzetvsdoel p.percentage').text(totalpercentage + '%');

        if (quantitydonut >= totaldonut) {
            quantitydonut = totaldonut;
            if(nogtehalen <= 0){
                nogtehalen = 0;
            }
            new Chartist.Pie('#omzetvsdoel', {
                series: [quantitydonut, nogtehalen]
            }, {
                donut: true,
                donutWidth: 25,
                donutSolid: true,
                startAngle: 270,
                total: totaalmaaltwee,
                showLabel: false,
                fullWidth: true
            });
        }else{
            new Chartist.Pie('#omzetvsdoel', {
                series: [quantitydonut, nogtehalen]
            }, {
                donut: true,
                donutWidth: 25,
                donutSolid: true,
                startAngle: 270,
                total: totaalmaaltwee,
                showLabel: true,
                fullWidth: true
            });
        }



        //chart 5
        var quantitydonut = parseInt($('.omzetvsdoeljaar .quantitydonut').html());
        var totaldonut = parseInt($('.omzetvsdoeljaar .totaldonut').html());
        var totalpercentage = ((quantitydonut/totaldonut)*100).toFixed(0);
        var nogtehalen = totaldonut - quantitydonut;
        var totaalmaaltwee = totaldonut * 2;

        $('.omzetvsdoeljaar p.percentage').text(totalpercentage + '%');

        if (quantitydonut >= totaldonut) {
            quantitydonut = totaldonut;
            if(nogtehalen <= 0){
                nogtehalen = 0;
            }
            new Chartist.Pie('#omzetvsdoeljaar', {
                series: [quantitydonut, nogtehalen]
            }, {
                donut: true,
                donutWidth: 25,
                donutSolid: true,
                startAngle: 270,
                total: totaalmaaltwee,
                showLabel: false,
                fullWidth: true
            });
        }else{
            new Chartist.Pie('#omzetvsdoeljaar', {
                series: [quantitydonut, nogtehalen]
            }, {
                donut: true,
                donutWidth: 25,
                donutSolid: true,
                startAngle: 270,
                total: totaalmaaltwee,
                showLabel: true,
                fullWidth: true
            });
        }
    });



    function ulheight(){
        var windowheight = $(window).height();
        var navheight =  $('.topnavigation').outerHeight();
        var topformheight = $('.topinformation').outerHeight();
        var ulheight = windowheight - navheight - topformheight - 130;
        var heighttwentyfive = (ulheight / 100) * 25;
        var heightfifty = (ulheight / 100) * 50 ;
        var heightseventyfive = (ulheight / 100) * 75;
        $('#dashboardContent .smallgrid').height(heighttwentyfive);
        $('#dashboardContent .bigheight').css({height: 'calc('+heightfifty+'px + 1em)'});
        $('#dashboardContent .bigheightlong').css({height: 'calc('+heightfifty+'px + 1em)'});
        $('#dashboardContent .bigheightlonger').css({height: 'calc('+heightseventyfive+'px + 2em)'});
    }
    ulheight();
    $(window).resize(function(){
        ulheight();
        topheader();

    });

    //line chart 1
    var quantitycounter = parseInt($('.offertesvsafspraken .quantitycounter').html());
    var totalcounter = parseInt($('.offertesvsafspraken .totalcounter').html());
    var totalpercentage = (quantitycounter/totalcounter)*100;

    if (totalcounter == 0) {
        var totalpercentage = 0;
    }

    var marginleft = totalpercentage - 3;

    if(marginleft >= 100){
        var marginleft = 48;
    }

    $('.offertesvsafspraken .quantitycounter').css({marginLeft: marginleft + '%'});

    $('#offertesvsafspraken').LineProgressbar({
        percentage: totalpercentage,
        radius: '3px',
        height: '25px',
        fillBackgroundColor: '#fdc834'
    });

    //line chart 2
    var quantitycounterSec = parseInt($('.contractenvsafspraken .quantitycounter').html());
    var totalcounterSec = parseInt($('.contractenvsafspraken .totalcounter').html());
    var totalpercentageSec = (quantitycounterSec/totalcounterSec)*100;

    if (totalcounterSec == 0) {
        var totalpercentageSec = 0;
    }
    var marginleftSec = totalpercentageSec - 3;

    if(marginleftSec >= 100){
        var marginleft = 48;
    }
    $('.contractenvsafspraken .quantitycounter').css({marginLeft: marginleftSec + '%'});

    $('#contractenvsafspraken').LineProgressbar({
        percentage: totalpercentageSec,
        radius: '3px',
        height: '25px',
        fillBackgroundColor: '#fdc834'
    });

    //line chart 3
    var quantitycounterThird = parseInt($('.contractenvsoffertes .quantitycounter').html());
    var totalcounterThird = parseInt($('.contractenvsoffertes .totalcounter').html());
    var totalpercentageThird = (quantitycounterThird/totalcounterThird)*100;

    if (totalcounterThird == 0) {
        var totalpercentageThird = 0;
    }
    var marginleftThird = totalpercentageThird - 3;

    if(marginleftThird >= 100){
        var marginleft = 48;
    }

    $('.contractenvsoffertes .quantitycounter').css({marginLeft: marginleftThird + '%'});

    $('#contractenvsoffertes').LineProgressbar({
        percentage: totalpercentageThird,
        radius: '3px',
        height: '25px',
        fillBackgroundColor: '#fdc834'
    });

    window.dispatchEvent(new Event('resize'));



    $('.hoogte').on('change', function(){
        var h = $(this).attr('name');
        var val = $(this).val();

        $('[name=' + h + ']').each(function(){
            $(this).val(val);
        })
    });

    $('.breedte').on('change', function(){
        var b = $(this).attr('name');
        var val = $(this).val();

        $('[name=' + b + ']').each(function(){
            $(this).val(val);
        })
    });

    $("form").bind("keypress", function(e) {
        if (e.keyCode == 13) {
            return false;
        }
    });

    $('[name="hanggoot"]').on('click', function(){
        if ($(this).val() == 'Bestaande') {
            $('[name="ZHG_rond"]').val(0).keydown().keypress().keyup().blur();
            $('[name="ZHG_vierkant"]').val(0).keydown().keypress().keyup().blur();

            $('#hanggoot_rond').prop('checked', false);
            $('.hanggoot_rond').addClass('hidden');
            $('#hanggoot_vierkant').prop('checked', false).addClass('hidden');
            $('.hanggoot_vierkant').addClass('hidden');
        }

        if ($(this).val() == 'Nieuwe') {
            $('[name="BDA"]').val(0).keydown().keypress().keyup().blur();
        }
    });

    $('.first-step').append($('form.ontmossen'));
    $('.first-step').append($('form.blanco'));

    $('ul.tabs li').click(function(){
		var tab_id = $(this).attr('data-tab');

		$('ul.tabs li').removeClass('current');
		$('.tab-content').removeClass('current');

		$(this).addClass('current');
		$("#"+tab_id).addClass('current');
	});

    $('.tags label').on('click', function(){
        $('.tags .label.active').removeClass('active');
        $(this).parent().addClass('active');
    });

    initDatePickers();
    initDropzone();

    autoTrigger();

    price = 0;
    //
    // $( "#sortable" ).sortable({
    //     placeholder: "ui-state-highlight",
    //     start: function (event, ui) {
    //         $(ui.item).data("startindex", ui.item.index());
    //     },
    //     stop: function (event, ui) {
    //         sendUpdatedIndex(ui.item);
    //     }
    // });
    //
    // $( "#sortable" ).disableSelection();

    // Steps
    // $(document).on('click', '.offerte-maken h2', function(){ toggleSection($(this)); }); //Open dropdown (Stap 1)
    $(document).on('click', '.offerte-maken h2', function(){ if(!$(this).parent().hasClass('done') && !$(this).parent().hasClass('open-done')){ nextSection(event, $(this)); } else { toggleSection($(this))} }); //Open dropdown (Stap 1)
    $(document).on('click touchstart', '.first-step .active input[type="radio"], label.next, label.next-btn', function(){ nextSection(event, $(this)); }); // Open volgende dropdown (Stap 1)
    $(document).on('click', '.first-step .done', function(){ reOpen($(this), true); }); // Re-open tab
    $(document).on('click', '.first-step .reopened', function(){ reOpen($(this), false); }); // Re-open tab
    $(document).on('keyup', '.first-step input[name="oppervlakte"], .first-step input[name="oppervlakte_plat"], .first-step input[name="oppervlakte_hellend"]', function(){ isValid($(this)); });
    $(document).on('keyup', '.search-product', function(){ searchProduct($(this)); });
    $(document).on('keyup', '[kost]', function(){calcKosten($(this)); });


    $(document).on('change', 'select[name="HVEa"], select[name="HVEb"], select[name="HVEc"], select[name="HVEd"], select[name="HVEe"]', function(){ changeUnit($(this)); });
    $(document).on('change', 'select[name="VSI"]', function(){ changeUnit2($(this)); });


    // Offerte detail
    $(document).on('click', '.offerte-maken.detail .radio-toolbar input[type="checkbox"]', function(){ showInput($(this)); });
    // $(document).on('change', '.hidden-input input, .hidden input', function(){ calcProductPrice($(this)); });
    $(document).on('keyup', 'input[product-code]', function(){ calculateEasy($(this)); });
    $(document).on('change', '.double.select', function(){ updatePrice($(this)); });
    $(document).on('change', '.triple.select', function(){ getTriples($(this)); });
    $(document).on('change', '.scnd.select', function(){ getTriplesSub($(this)); });
    $(document).on('keyup', 'input[xtr]', function(){ calculateExtra($(this)); }); // Bereken extra's
    $(document).on('keyup', 'input[kort]', function(){ calculateKorting($(this)); }); // Bereken korting
    $(document).on('click', '.add-blanco', function(){ addBlanco($(this)); }); // Extra's toevoegen
    $(document).on('click', '.add-extras', function(){ addExtra($(this)); }); // Extra's toevoegen
    $(document).on('click', '.add-extras-plat', function(){ addExtraPlat($(this)); }); // Extra's toevoegen
    $(document).on('click', '.add-korting', function(){ addKorting($(this)); }); // Extra's toevoegen
    $(document).on('click', '.add-korting-plat', function(){ addKortingPlat($(this)); }); // Extra's toevoegen (plat)
    $(document).on('click', '[name="crepi_isolatie"], [name="thermo_isolatie"]', function(){ addGevelIsolatie($(this)); });
    $(document).on('click', '[name="vensters_meegeteld"]', function(){ showExtraCostVenster($(this)); });

    $(document).on('click', '.comment-btn', function(){ showCommentField($(this)); }); // Extra's toevoegen

    $(document).on('change', '.triple, .scnd, .thrd', function(){ calcDakBedekking($(this)); }); //
    $(document).on('change', '.dakraam_referentie, .dakraam_type, .dakraam_energie, .dakraam_afwerking', function (){ getDakraam($(this), true); });
    $(document).on('keyup', '.dakraam_hoeveelheid', function (){ getDakraam($(this), false); });
    $(document).on('keyup', '[name=koepel_hoeveelheid1], [name=koepel_hoeveelheid2], [name=koepel_hoeveelheid3], [name=koepel_hoeveelheid4]', function (){ getKoepel($(this)); });
    $(document).on('keyup', '[name=voetstuk_hoeveelheid1], [name=voetstuk_hoeveelheid2], [name=voetstuk_hoeveelheid3], [name=voetstuk_hoeveelheid4]', function (){ getVoetstuk($(this)); });
    $(document).on('change', '[name=koepel_referentie1], [name=koepel_referentie2], [name=koepel_referentie3], [name=koepel_referentie4]', function (){ selectSame($(this)); });
    $(document).on('change', '[name=velux_referentie1]', function (){ selectSameVelux($(this)); });


    $(document).on('click', '.toggle-optie', function(){ toggleAdmin($(this)); }); // Extra's toevoegen
    $(document).on('change', '[name="korrel_dikte"]', function(){ changeKorrelDikte($(this)); });
    $(document).on('change', '[name="ZIS_vloer"]', function(){ changeNoMineral($(this)); });
    $(document).on('change', '[name="ZIS_hellend"]', function(){ changeNoMineralHellend($(this)); });

    // Lood & Zinkwerken
    $(document).on('change', 'input[type="checkbox"]', function(){ showExtraInput($(this)); });
    $(document).on('change', '.lood_zink input[type="radio"]', function(){ showRightInput($(this)); });
    $(document).on('keyup', 'input[name="KIS_hoeveelheid"], input[name="LGO_hoeveelheid"], input[name="LKE_hoeveelheid"], input[name="HVEa_hoeveelheid"], input[name="HVEb_hoeveelheid"], input[name="HVEc_hoeveelheid"], input[name="HVEd_hoeveelheid"], input[name="KIE_hoeveelheid"], input[name="INZ_hoeveelheid"], input[name="LGO_hoeveelheid"], input[name="PGO_hoeveelheid"], input[name="VGO_hoeveelheid"], input[name="VKE_hoeveelheid"], input[name="PKE_hoeveelheid"], input[name="LKE_hoeveelheid"], input[name="OTI_hoeveelheid"], input[name="DUR_hoeveelheid"],  input[name="DKT_hoeveelheid"], input[name="GDO_hoeveelheid"], input[name="BTI_hoeveelheid"]', function(){ autoFill($(this)); });

    // Extra dakbedekking toevoegen
    $(document).on('click', '.extra_dakbedekking', function(){ extraDakBedekking($(this)); }); // Extra dakbedekking toevoegen
    $(document).on('click', '.extra_dakramen', function(){ extraDakramen($(this)); }); // Extra dakbedekking toevoegen
    $(document).on('click', '.extra-koepel', function(){ extraKoepel($(this)); }); // Extra koepel toevoegen
    $(document).on('click', '[name="noordbomen_aanwezig"]', function(){ extraNoordboom($(this)); });
    $(document).on('click', '[name="kielgoot_aanwezig"]', function(){ kielgoot($(this)); });

    $(document).on('click', '.beheer-product h2', function(){ openPrices($(this)); });

    $(document).on('click', '.concept', function(){
        $('form.form-horizontal').append('<input type="hidden" name="concept" value="true">');
        $('form.form-horizontal').submit();
    });

    $(document).on('click', '[name="dakconstructie"]', function(){
        if ($(this).val() != 'volledig') {
            $('.uitrechten').show();
        } else {
            $('.uitrechten').hide();
        }
    });

    $(document).on('change', '[name="postcode"]', function(){
        var postcode = $(this).val();

        $.getJSON('https://maps.googleapis.com/maps/api/geocode/json?address=' + postcode + '+Belgium&sensor=true').done(function(data) {
            var plaats = data.results[0]['address_components'][1]['long_name'];
            $('[name="plaats"]').val("").val(plaats);
        });
    });

    $(document).on('click', '[name="stelling_nodig"]', function(){
        if ($(this).val() != 'Nee') {
            $('.stelling-nodig').show();
        } else {
            $('.stelling-nodig').hide();
        }
    });

    $(document).on('click', '[name="uitrechten"]', function(){
        if ($(this).val() == 'ja') {
            $('.uitrechten-reminder').hide();
            $('.uitrechten_hoeveelheid').show();
        } else {
            $('.uitrechten-reminder').show();
            $('.uitrechten_hoeveelheid').hide();
        }
    });

    $('[name="stelling_plat_nodig"]').on('click', function(){
        if ($(this).val() == "Nee") {
            $('.stelling-afh').hide();
        } else {
            $('.stelling-afh').show();
        }
    });

    $('input[type="text"]').keydown().keypress().keyup().blur();

    $('input[type="radio"][checked="checked"]').click();

    // var stelling_price = 0;
    // var arTest = [];
    //
    // $('.stelling').each(function(){
    //     var val = $(this).val();
    //
    //     $.ajax({
    //         url: '/stelling/' + val,
    //         type: 'GET',
    //         async: false,
    //         contentType: "application/json; charset=utf-8",
    //         dataType:"json",
    //         success: function (data){
    //             stelling_price += data;
    //             $('input[product-id="VAL"]').val(stelling_price);
    //             $('.product_price.VAL').text(stelling_price);
    //         }
    //     });
    // });
    // console.log(stelling_price);


    // Bepaalde velden meteen aanzetten
    var size = $('input[name="project_size"]').val();

    // $('input[name="STO"]').click();
    // $('input[name="STO_hoeveelheid"]').val(size).keydown().keypress().keyup().blur();

    if (size <= 74) {
        var val = 1;
    } else {
        var val = 2;
    }
    if (!$('input[name="BMA"]').is(':checked')) {
        $('input[name="BMA"]').click();
    }
    $('input[name="BMA_hoeveelheid"]').val(val).keydown().keypress().keyup().blur();
    // END

    // Stellingen
    $('input[name="hoeveelheid_stellingen"], input[name="hoeveelheid_stellingen_combi"]').on('keyup', function(){
        var val = $(this).val();
        $('.stelling').hide();

        if ($.isNumeric(val)) {
            $('.stelling').each(function(){
                var nr = $(this).attr('stelling');

                if (nr <= val) {
                    $(this).show();
                }
            });
            $(".hoeveelheid_stellingen, .hoeveelheid_stellingen_combi").show().removeClass('hidden');
        }
    });

    // Blauwsteen (Gevelisolatie)
    $('input[name="blauwsteen_dorpels"]').on('click', function(){
        if ($(this).val() == "Ja") {
            $('.hoeveelheid_blauwsteen').removeClass('hidden').show();
        } else {
            $('.hoeveelheid_blauwsteen').addClass('hidden').hide();
            $('[name="hoeveelheid_blauwsteen"]').val("").keydown().keypress().keyup().blur();
        }
    });

    $('input[name="hoeveelheid_blauwsteen"]').on('keyup', function(){
        var val = $(this).val();
        $('.blauwsteen').hide();

        if ($.isNumeric(val)) {
            $('.blauwsteen').each(function(){
                var nr = $(this).attr('blauwsteen');

                if (nr <= val) {
                    $(this).show();
                }
            });
            $(".hoeveelheid_blauwsteen").show().removeClass('hidden');
        }
    });

    $('.metric').on('keyup', function(){
        var size = 0;
        $('.hoeveelheid_stellingen .stelling[stelling]:visible').each(function(){
            var parent = $(this).parent().parent().attr('stelling');


            var x = $(this).find('input.breedte').val();
            var y = $(this).find('input.hoogte').val();
            if (x && y) {
                size += (parseFloat(x) * parseFloat(y));
            }
        });

        $('input[name="stelling_size"').remove();
        $(this).parent().append('<input name="stelling_size" type="hidden" value="' + size + '">');

    });

    $('.hoogte').on('keyup', function(){
        var parent = $(this).parent().parent().attr('stelling');
        var val = $(this).val();
        val = val.toString().replace(/\,/g, '.');
        var stelling = 'VAL_1';

            if (val <= 4) {
                stelling = 'VAL_1';
            } else if(val > 4 && val <= 7) {
                stelling = 'VAL_2';
            } else if(val > 7) {
                stelling = 'VAL_3';
            }

            $('input[stelling="' + parent + '"').remove();
            $(this).parent().append('<input name="VAL' + parent +  '" type="hidden" stelling="' + parent + '" value="' + stelling + '">');
    });

    // Schouw verwijderen? (3-staps)
        $('input[name="schouw_aanwezig"]').on('click', function(){
            if ($(this).val().toLowerCase() == "ja") {
                $(".hoeveelheid_schouwen").show().removeClass('hidden');
            } else {
                $(".hoeveelheid_schouwen").hide().addClass('hidden');
            }
        });

        $('.hoeveelheid_schouwen').on('keyup', function(){
            if ($.isNumeric($('input[name="hoeveelheid_schouwen"]').val())) {
                $(".schouw_afbreken").show().removeClass('hidden');
            } else {
                $(".schouw_afbreken").hide().addClass('hidden');
            }
        });

        $('input[name="schouw_afbreken"]').on('click', function(){
            if ($(this).val().toLowerCase() == "ja") {
                $(".hoeveelheid_schouwen_afbreken").show().removeClass('hidden');
            } else {
                $(".hoeveelheid_schouwen_afbreken").hide().addClass('hidden');
                $('input[name="SCH_hoeveelheid"]').val("").keydown().keypress().keyup().blur();
                $('input[name="HSC_hoeveelheid"]').val("").keydown().keypress().keyup().blur();
            }
        });

        $('input[name="kiezels_aanwezig"]').on('click', function(){
            if ($(this).val().toLowerCase() == "ja") {
                $(".kiezels_weg").show().removeClass('hidden');
            } else {
                $(".kiezels_weg").hide().addClass('hidden');
                $('input[name="SCH_hoeveelheid"]').val("").keydown().keypress().keyup().blur();
                $('input[name="HSC_hoeveelheid"]').val("").keydown().keypress().keyup().blur();
            }
        });

        $('input[name="dakbedekking_behouden"]').on('click', function(){
            if ($(this).val().toLowerCase() == "nee") {
                $(".dakbedekking_weg").show().removeClass('hidden');
            } else {
                $(".dakbedekking_weg").hide().addClass('hidden');
            }
        });

        $('input[name="SCH_hoeveelheid"], input[name="HSC_hoeveelheid"]').on('keyup', function(){
            var kl = $('input[name="SCH_hoeveelheid"]').val();
            var gr = $('input[name="HSC_hoeveelheid"]').val();

            if (isNaN(kl) || kl == '') { kl = 0;}
            if (isNaN(gr) || gr == '') { gr = 0;}

            var aantal = parseInt(kl) + parseInt(gr) - parseInt($('input[name="hoeveelheid_schouwen"]').val());
            aantal *= -1;

            if ((parseInt(kl) + parseInt(gr)) < parseInt($('input[name="hoeveelheid_schouwen"]').val())) {
                $('.reminder.schouwen').show();
                $('[name="NLS_hoeveelheid"]').addClass('click-me').val(aantal);
                $('[name="NLS').attr('checked', 'checked');
                $('.NLS').show();

                $('.click-me').keydown().keypress().keyup().blur(); // Edit mode
                // $('.click-me').click();
            } else {
                $('.reminder.schouwen').hide();
                $('[name="NLS_hoeveelheid"]').removeClass('click-me').val(0);
                $('[name="NLS').removeAttr('checked');
                $('.NLS').hide();
            }
        });

    // Vensterbank aanpassen
    $('input[name="vensterbank_aanpassen"]').on('click', function(){
        if ($(this).val().toLowerCase() == "ja") {
            $(".hoeveelheid_vensterbanken").show().removeClass('hidden');
        } else {
            $(".hoeveelheid_vensterbanken").hide().addClass('hidden');
        }
    });

    $('[name=hoeveelheid_vensterbanken]').on('keyup', function(){
        var val = $(this).val();
        $('.vensterbank').hide();

        if ($.isNumeric($('input[name="hoeveelheid_vensterbanken"]').val())) {
            $('.vensterbank').each(function(){
                var nr = $(this).attr('vensterbank');

                if (nr <= val) {
                    $(this).show();
                }
            });
        } else {
            $(".schouw_afbreken").hide().addClass('hidden');
        }
    });

    // Dakramen verwijderen? (3-staps)
        $('input[name="dakraam_aanwezig"]').on('click', function(){

            if ($(this).val().toLowerCase() == "ja") {
                $(".hoeveelheid_dakramen").show().removeClass('hidden');

                // $(".dakraam_hergebruiken").show().removeClass('hidden');
                // $(".geen_dakraam").hide().addClass('hidden');
            } else {
                $(".hoeveelheid_dakramen").hide().addClass('hidden');
                // $(".dakraam_hergebruiken").hide().addClass('hidden');
                $(".geen_dakraam").show().removeClass('hidden');
            }
        });

        $('input[name="VEP_hoeveelheid"]').on('change', function(){
            var gr = $('input[name="VEP_hoeveelheid"]').val();
            $('input[name="PVE"]').trigger('click');
            $('[name="PVE_hoeveelheid"]').val(gr).keydown().keypress().keyup().blur();
        });

        $('input[name="BVE_hoeveelheid"], input[name="VEP_hoeveelheid"]').on('keyup', function(){
            var kl = $('input[name="BVE_hoeveelheid"]').val();
            var gr = $('input[name="VEP_hoeveelheid"]').val();

            if (isNaN(kl) || kl == '') { kl = 0;}
            if (isNaN(gr) || gr == '') { gr = 0;}

            var aantal = parseInt(kl) + parseInt(gr) - parseInt($('input[name="hoeveelheid_dakramen"]').val());
            aantal *= -1;

            // console.log((parseInt(kl) + parseInt(gr)) < parseInt($('input[name="hoeveelheid_dakramen"]').val()));
            // if ((parseInt(kl) + parseInt(gr)) < parseInt($('input[name="hoeveelheid_dakramen"]').val())) {
            //     $('[name="VDA_hoeveelheid"]').addClass('click-me').val(aantal);
            //     $('[name="VDA').attr('checked', 'checked');
            //     $('.VDA').show();
            //
            //     $('.click-me').keydown().keypress().keyup().blur(); // Edit mode
            //     $('.click-me').click();
            // } else {
            //     $('[name="VDA_hoeveelheid"]').removeClass('click-me').val(0);
            //     $('[name="VDA').attr('checked', '');
            // }
        });

        $('.hoeveelheid_dakramen').on('keyup', function(){
            if($.isNumeric($('input[name="hoeveelheid_dakramen"]').val())) {
                $(".dakraam_hergebruiken").show().removeClass('hidden');
                $(".geen_dakraam").hide().addClass('hidden');
            } else {
                $(".dakraam_hergebruiken").hide().addClass('hidden');
                // $(".geen_dakraam").show().removeClass('hidden');
            }
        });

        $('input[name="dakraam_nodig"]').on('click', function(){
            if ($(this).val().toLowerCase() == "ja") {
                $(".dakraam_hergebruiken").show().removeClass('hidden');
            } else {
                $(".dakraam_hergebruiken").hide().addClass('hidden');
            }
        });

        $('input[name="dakraam_hergebruiken"]').on('click', function(){
            if ($(this).val().toLowerCase() == "hergebruiken") {
                $(".hoeveelheid_dakramen").show().removeClass('hidden');
            } else {
                $(".hoeveelheid_dakramen").hide().addClass('hidden');
            }
        });

        $('.hoeveelheid_dakramen').on('keyup', function(){
            if ($.isNumeric($('input[name="hoeveelheid_schouwen"]').val())) {
                $(".schouw_afbreken").show().removeClass('hidden');
            } else {
                $(".schouw_afbreken").hide().addClass('hidden');
            }
        });

    // Parkeerverbod: breedte van straat & voetpad vragen
    $('input[name="parkeerverbod"]').on('change', function(){
        if ($(this).val().toLowerCase() == "ja") {
            $('.stelling-plaatsing').slideDown();
        } else {
            $('.stelling-plaatsing').slideUp();
        }
    });

    $('label[dak]').on('click', function(){
        if ($(this).attr('dak') == 'hellend') {
            $('.dakwerken.plat').hide();
            $('.dakwerken.combi').hide();
            $('.dakwerken.hellend').show();
        }
        if ($(this).attr('dak') == 'plat') {
            $('.dakwerken.plat').show();
            $('.dakwerken.combi').hide();
            $('.dakwerken.hellend').hide();
        }
        if ($(this).attr('dak') == 'combinatie') {
            $('.dakwerken.plat').hide();
            $('.dakwerken.hellend').hide();
            $('.dakwerken.combi').show();
        }
    });

    // Reminder tonen (daktimmer - timmerwerken)
    $('input[name="daktimmer"]').on('click', function(){
        if ($(this).hasClass('daktimmer-verwijderen')) {
            $('.daktimmer.reminder').show();
        } else {
            $('.daktimmer.reminder').hide();
        }
    });

    $('.click-me').keydown().keypress().keyup().blur(); // Edit mode
    $('.click-me').click();

    $('.change-me').trigger('change');
    resetDakBedekking();


    getVariants();
});

function changeUnit(that) {
    var val = $(that).find(':selected').val();
    var nr = val.split('_')[1];

    if (nr == 1 || nr == 5 || nr == 9 || nr == 13 || nr == 17) {
        $(that).parent().parent().find('.eenheid').empty().html('m²');
    } else {
        $(that).parent().parent().find('.eenheid').empty().html('lm');
    }
}

function changeUnit2(that) {
    var val = $(that).find(':selected').val();
    var nr = val.split('_')[1];
    console.log(nr);
    if (nr == 1) {
        $(that).parent().parent().find('.eenheid').empty().html('m²');
    } else {
        $(that).parent().parent().find('.eenheid').empty().html('lm');
    }
}

function sendUpdatedIndex ($item) {
    // increment by 1 as we're using 1 based index on server
    var startIndex = $item.data("startindex") + 1;
    var newIndex = $item.index() + 1;

    if (newIndex != startIndex) {
        $.ajax({
            url: '/order/' + startIndex + '/' + newIndex,
            type: 'GET',
            async: false,
            contentType: "application/json; charset=utf-8",
            dataType:"json",
            success: function (data){
                console.log(data);
            }
        });
    }
}

function changeKorrelDikte(that)
{
    if ($(that).val() == "1.0") {
        $('.korrel_d').show();
    } else {
        $('.korrel_d').hide();
    }
}

function changeNoMineral(that) {
    if (!$('option:selected', that).hasClass('no-mineral')) {
        $('.trig-no-mineral').show();
    } else {
        $('.trig-no-mineral').hide();
    }
}

function changeNoMineralHellend(that) {
    if (!$('option:selected', that).hasClass('no-mineral')) {
        $('.trig-no-mineral').show();
    } else {
        $('.trig-no-mineral').hide();
    }
}

function addGevelIsolatie(that)
{
    if ($(that).val() == 'Gedeeltelijk') {

        if ($(that).attr('name') == 'crepi_isolatie') {
            $('.hoeveel-iso-crepi').show().css({'display': 'block'});
            $('.dikte-crepi').show();
        } else {
            $('.hoeveel-iso-thermo').show().css({'display': 'block'});
            $('.dikte-thermo').show();
        }


    } else {


        if ($(that).attr('name') == 'crepi_isolatie') {

            if ($(that).val() == 'Volledig') {
                $('.dikte-crepi').show();
            } else {
                $('.dikte-crepi').hide();
            }

            $('.hoeveel-iso-crepi').hide().css({'display': 'none'});
            $('[name="crepi_iso_opp"]').val('0');
        } else {
            if ($(that).val() == 'Volledig') {
                $('.dikte-thermo').show();
            } else {
                $('.dikte-thermo').hide();
            }

            $('.hoeveel-iso-thermo').show().css({'display': 'none'});
            $('[name="thermo_iso_opp"]').val('0');
        }

        $('[name="iso_opp"]').val('');
    }

    // Show extra input (boordsteen)
    if ($(that).val() != 'Volledig' && $(that).attr('name') == 'crepi_isolatie') {
        $('.else-iso').show().css({'display': 'block'});
    } else {
        if ($(that).attr('name') == 'crepi_isolatie') {
            $('.else-iso').hide().css({'display': 'none'});
        }
    }
}

function showExtraCostVenster(that)
{
    if ($(that).val() == 'Nee') {
        $('.vensters_meegeteld').show().css({'display': 'block'});
    } else {
        $('.vensters_meegeteld').hide().css({'display': 'none'});
    }
}

function toggleAdmin(that)
{
    var optie_id = $(that).parent().attr('opties');

    $(that).next('.opties-block').slideToggle();
    $(that).find('.fa').toggleClass('fa-angle-up fa-angle-down');
}

function showCommentField(that)
{
    var step = $(that).attr('step');
    $('.opmerkingen_' + step).slideToggle();
}

function getDakraam(that, update)
{
    if (update) {
        var id = $(that).parent().parent().attr('dakraam');
    } else {
        var id = $(that).attr('dakraam');
    }

    var referentie = $('.dakbedekking[dakraam=' + id + ']').find('.dakraam_referentie').find('option:selected').val();
    var type = $('.dakbedekking[dakraam=' + id + ']').find('.dakraam_type').find('option:selected').val();
    var energie = $('.dakbedekking[dakraam=' + id + ']').find('.dakraam_energie').find('option:selected').val();
    var afwerking = $('.dakbedekking[dakraam=' + id + ']').find('.dakraam_afwerking').find('option:selected').val();

    var amount = $('.dakraam' + id + '_hoeveelheid').val().replace(',', '.');

    if (referentie && type && energie && amount) {
        $.ajax({
            url: '/dakraam/price/' + referentie + '/' + type + '/' + energie + '/' + amount + '/' + afwerking,
            type: 'GET',
            async: false,
            contentType: "application/json; charset=utf-8",
            dataType:"json",
            success: function (data){
                $('.dakraam' + id + '_hoeveelheid').empty();
                $('.dakraam' + id + '_price').text('€' + data.toFixed(2).toString().replace(/\./g, ','));

                if ($('.sub_total[product-id="dakraam' + id + '"]')[0]) {
                    $('.sub_total[product-id="dakraam' + id + '"]').remove();
                }
                var parent = $(that).parent().parent().parent().parent();
                $(parent).append('<input class="sub_total" type="hidden" product-id="dakraam' + id + '" value="' + data.toFixed(2) + '">');
                reCalculateSub(parent);
            }
        });
    }
}

function getKoepel(that)
{
    var hoeveelheid = $(that).val().replace(',', '.');
    var nr = $(that).attr('koepel');
    var koepel_id = $('[name="koepel_referentie' + nr + '"] ').find('option:selected').val();
    var type = $('[name="koepel_type' + nr + '"] ').find('option:selected').val();

    if (koepel_id && type && nr && hoeveelheid) {
        $.ajax({
            url: '/koepel/price/' + koepel_id + '/' + type + '/' + hoeveelheid,
            type: 'GET',
            async: false,
            contentType: "application/json; charset=utf-8",
            dataType:"json",
            success: function (data){
                // $('.koepel' + id + '_hoeveelheid').empty();
                $('.koepel' + nr + '_price').text('€' + data.toFixed(2).toString().replace(/\./g, ','));

                if ($('.sub_total[product-id="koepel' + nr + '"]')[0]) {
                    $('.sub_total[product-id="koepel' + nr + '"]').remove();
                }
                var parent = $(that).parent().parent().parent().parent();
                $(parent).append('<input class="sub_total" type="hidden" product-id="koepel' + nr + '" value="' + data.toFixed(2) + '">');
                reCalculateSub(parent);
            }
        });
    }
}

function getVoetstuk(that)
{
    var hoeveelheid = $(that).val().replace(',', '.');
    var nr = $(that).attr('koepel');
    var voetstuk_id = $('[name="voetstuk' + nr + '"] ').find('option:selected').val();
    var type = $('[name="voetstuk_type' + nr + '"] ').find('option:selected').val();

    if (voetstuk_id && type && nr && hoeveelheid) {
        $.ajax({
            url: '/voetstuk/price/' + voetstuk_id + '/' + type + '/' + hoeveelheid,
            type: 'GET',
            async: false,
            contentType: "application/json; charset=utf-8",
            dataType:"json",
            success: function (data){
                // $('.koepel' + id + '_hoeveelheid').empty();
                $('.voetstuk' + nr + '_price').text('€' + data.toFixed(2).toString().replace(/\./g, ','));

                if ($('.sub_total[product-id="voetstuk' + nr + '"]')[0]) {
                    $('.sub_total[product-id="voetstuk' + nr + '"]').remove();
                }
                var parent = $(that).parent().parent().parent().parent();
                $(parent).append('<input class="sub_total" type="hidden" product-id="voetstuk' + nr + '" value="' + data.toFixed(2) + '">');
                reCalculateSub(parent);
            }
        });
    }
}

// Toon juiste input velden
// Nieuw VS Bestaand
function showRightInput(that)
{
    var nieuwe = $(that).parent().parent().parent().parent().find('.nieuwe');
    var bestaande = $(that).parent().parent().parent().parent().find('.bestaande');
    if ($(that).val().toLowerCase() == "nieuwe") {
        $(nieuwe).removeClass('hidden');
        $(bestaande).addClass('hidden');
    } else {
        $(nieuwe).addClass('hidden');
        $(bestaande).removeClass('hidden');
    }
}

function updatePrice(that)
{
    productcode = $(that).attr('product-code');
    $('input[product-code=' + productcode + ']').keydown().keypress().keyup().blur();
}

function showExtraInput(that)
{
    var toggle = $(that).attr('toggle');
    if (toggle != "") {
        $('.custom-checkbox .' + toggle).toggleClass('hidden');
    }
}

function autoTrigger()
{
    // var arAffects = ['BMA', 'TOE'];

    // for (var i = 0; i < arAffects.length; i++) {
    //     if (!$('input[name="' + arAffects[i] + '_hoeveelheid"]').val() && !$('.' +  arAffects[i]).is(':checked')) {
    //         $('.' +  arAffects[i]).show();
    //         $('input[name="' + arAffects[i] + '"]').trigger('click');
    //         $('input[name="' + arAffects[i] + '_hoeveelheid"]').val(2).keydown().keypress().keyup().blur();
    //     } else {
    //         $('input[name="' + arAffects[i] + '_hoeveelheid"]').val(0).keydown().keypress().keyup().blur();
    //     }
    // }
}

function autoFill(that)
{
    var val = $(that).val();
    var trigger = $(that).attr('name');

    if (trigger == "KIS_hoeveelheid") {
        var selected = $('select.double[name="KIS"]').find(':selected').val();

        if (selected == 'KIS_1' || selected == 'KIS_2' || selected == 'KIS_3' || selected == 'KIS_4') {
            var affect = 'KDA';
        }
    } else if (trigger == 'LGO_hoeveelheid') {
        // var affect = 'PGO';
    } else if (trigger == 'HVEa_hoeveelheid' || trigger == 'HVEb_hoeveelheid' || trigger == 'HVEc_hoeveelheid' || trigger == 'HVEd_hoeveelheid' || trigger == 'LGO_hoeveelheid'
                || trigger == 'LGO_hoeveelheid' || trigger == 'PGO_hoeveelheid' || trigger == 'VGO_hoeveelheid' || trigger == 'VKE_hoeveelheid' || trigger == 'PKE_hoeveelheid'
                || trigger == 'LKE_hoeveelheid' || trigger == 'OTI_hoeveelheid' || trigger == 'DUR_hoeveelheid' || trigger == 'DKT_hoeveelheid' || trigger == 'GDO_hoeveelheid' || trigger == 'BTI_hoeveelheid') {
        var affect = 'TOE';
    } else if (trigger == 'KIE_hoeveelheid') {
        var affect = 'INZ';
        $('[name="kielgoot_aanwezig"][value="Ja"]').attr('checked', 'checked');
        $('.kielgoot-prod').addClass('fh').css({'display': 'block','height': '169px !important'});
    }


    if (!$('input[name="' +  affect + '"]:checked').length && $.isNumeric(val)) {
        $('input[name="' + affect + '"]').trigger('click');
        if (affect == 'TOE') {
            $('input[name="' + affect + '_hoeveelheid"]').val(1).keydown().keypress().keyup().blur();
        } else {
            $('input[name="' + affect + '_hoeveelheid"]').val(val).keydown().keypress().keyup().blur();
        }
    } else if ($('input[name="' + affect + '"]:checked').length && $.isNumeric(val)) {
        if (affect == 'TOE') {
            $('input[name="' + affect + '_hoeveelheid"]').val(1).keydown().keypress().keyup().blur();
        } else {
            $('input[name="' + affect + '_hoeveelheid"]').val(val).keydown().keypress().keyup().blur();
        }
    }


}

function resetDakBedekking()
{
    var pan = $('input[name="pan_sub_type_val"]').val();
    var kleur = $('input[name="kleur_pan_val"]').val();

    $('.scnd').find('option[value="' + pan + '"]').prop('selected', true).change();
    $('.thrd').find('option[value="' + kleur + '"]').prop('selected', true);
    $('input[name="DBD_hoeveelheid"]').keydown().keypress().keyup().blur();

    if ($('.x_pan').length) {
        $('.x_pan').each(function(){
            if ($(this).val()) {
                extraDakBedekking($(this));
                var nr = $(this).attr('nr');

                var dbd = $('input[name="dbd' + nr + '"]').val();
                var pan = $('input[name="pan_sub_type_val' + nr + '"]').val();
                var kleur = $('input[name="kleur_pan_val' + nr + '"]').val();

                $('.dak_bd[nr=' + nr + '] .frst').find('option[value="' + dbd + '"]').prop('selected', true).change();
                $('.dak_bd[nr=' + nr + '] .scnd').find('option[value="' + pan + '"]').prop('selected', true).change();
                $('.dak_bd[nr=' + nr + '] .thrd').find('option[value="' + kleur + '"]').prop('selected', true);
                var hoeveelheid = $('input[type="hidden"][name="f_DBD_hoeveelheid_' + nr + '"]').val();

                $('input[name="DBD_hoeveelheid_'+ nr + '"]').val(hoeveelheid).keydown().keypress().keyup().blur();
            }
        });
    }
}

function extraDakBedekking(that)
{
    var length = $('.dak_bd').length;
    var next = parseInt(length) + 1;

    $('.extra-dakbedekking_container').append('<br><br><div class="row dakbedekking dak_bd" nr="' + next + '"> <div class="col-md-6"> <h5>Type dakbedekking</h5> <select class="triple frst select ___DBD" product-code="___DBD" name="DBD_' + next + '"> <option disabled="disabled" selected="selected">Kies soort</option> <option product-code="DBD_1" value="1">Kleipannen</option> <option product-code="DBD_2" value="2">Betonpannen</option> <option product-code="DBD_3" value="3">Leien</option> <option product-code="DBD_4" value="4">Hergebruiken</option> </select> </div> <div class="col-md-6"> <h5>Sub-type dakbedekking</h5> <select class="tripple scnd select ___DBD" product-code="___DBD" name="pan_sub_type' + next + '"> <option disabled="disabled" value="0" selected="selected">Kies sub-type</option> </select> </div> <div class="col-md-6"> <h5>Kleur pan</h5> <select class="tripple thrd select ___DBD" product-code="___DBD" name="kleur_pan' + next + '"> <option disabled="disabled" value="0" selected="selected">Kies kleur</option> </select> </div> <div class="col-md-3"> <h5> &#8203;</h5> <fieldset style="float:left; width: 90%;"> <input type="text" product-code="___DBD" name="DBD_hoeveelheid_' + next + '" class="DBD"> <div class="eenheid">m²</div> </fieldset> <span style="float:right; width: 1%; font-size:20px;" class="product_price ___DBD"> €0,00</span> </div> </div');
}

function extraDakramen(that)
{
    var length = $('.dakbedekking [dakraam]').length;
    var hidden = $('.dakbedekking.hidden [dakraam]').length;
    var shown = parseInt(length) - parseInt(hidden);

    $('.dakbedekking[dakraam="' + (parseInt(shown) + 1) + '"]').removeClass('hidden').show();
}

function extraKoepel(that)
{
    var length = $('.koepel-block[koepel]').length;
    var hidden = $('.koepel-block.hidden[koepel]').length;
    var shown = parseInt(length) - parseInt(hidden);

    $('.koepel-block[koepel="' + (parseInt(shown) + 1) + '"]').removeClass('hidden').show();
}

function extraNoordboom(that)
{
    var val = $(that).val().toLowerCase();

    if (val == "ja") {
        $('.noordboom-prod').show();
    } else {
        $('.noordboom-prod').hide();
    }
}

function kielgoot(that)
{
    var val = $(that).val().toLowerCase();

    if (val == "ja") {
        $('.kielgoot-prod').show();
    } else {
        $('.kielgoot-prod').hide();
    }
}

var stelling = 0;
function calculateEasy(that)
{
    if ($(that).hasClass('DBD')) {
        calcDakBedekking($(that).parent());
        return false;
    }

    var part = $(that).closest('.section-wrapper');
    var productcode = $(that).attr('product-code');

    var double_product = productcode.includes('__');
    var nr = $(that).val().replace(',', '.');

    if (productcode == "LDA") {
        productcode = getDakType();

        double_product = false;
        var url = '/product/price/double/' + productcode + '/' + nr;
        productcode = 'LDA';
    }

    if (productcode != "LDA") {
        var url = '/product/price/double/' + productcode + '/' + nr;
    }


    if (double_product) {

        var productcode = $('select.' + productcode).find('option:selected').attr('product-code');

        url = '/product/price/double/' + productcode + '/' + nr;
        productcode = $(that).attr('product-code');
    }

    if (productcode == 'VAL') {
        var url = '/product/price/double/' + productcode + '/' + $('input[type="hidden"][name="offerte_id"]').val();
    }

    if (!isNaN(nr) && productcode != "" && nr != "" && $.isNumeric(nr)) {
        $.ajax({
            url: url,
            type: 'GET',
            contentType: "application/json; charset=utf-8",
            dataType:"json",
            success: function (data) {
               $('.product_price.' + productcode).text('€' + data.toFixed(2).toString().replace(/\./g, ','));

               if ($('.sub_total[product-id=' + productcode + ']')[0]) {
                   $('.sub_total[product-id=' + productcode + ']').remove();
               }
               var parent = $(that).parent().parent().parent().parent().parent();
               $(part).append('<input class="sub_total" type="hidden" product-id="' + productcode + '" value="' + data.toFixed(2) + '">');
               reCalculateSub(parent);
            }
        });
    } else {
        $('.product_price.' + productcode).text('€0,00');
        $('input[product-id="' + productcode + '"]').val(0);
        var parent = $(that).parent().parent().parent().parent().parent();
        reCalculateSub(parent);
    }


}

function getDakType()
{
    var klant_id = getUrlParameter('klant');
    var code = "";
    $.ajax({
        url: '/get/daktype/' + klant_id,
        type: 'GET',
        contentType: "application/json; charset=utf-8",
        async: false,
        success: function (data) {
            if (data.toLowerCase() == 'leien') {
                code = 'LDA_3';
            } else if (data.toLowerCase() == 'pannen') {
                code = 'LDA_1';
            } else if (data.toLowerCase() == 'riet') {
                code = 'LDA_2';
            }
        }
    });

    return code;
}

function calcProductPrice(that)
{
    // var id = $(that).parent().parent().attr('attr-id');
    var productcode = $(that).attr('product-code');
    var nr = $(that).val().replace(',', '.');

    if (!isNaN(nr) && productcode != "" && nr != "" && $.isNumeric(nr)) {
        $.ajax({
            url: '/product/price/' + productcode + '/' + nr,
            type: 'GET',
            contentType: "application/json; charset=utf-8",
            dataType:"json",
            success: function (data) {
               $('.product_price.' + productcode).text('€' + data.toFixed(2).toString().replace(/\./g, ','));

               if ($('.sub_total[product-id=' + productcode + ']')[0]) {
                   $('.sub_total[product-id=' + productcode + ']').remove();
               }
               var parent = $(that).parent().parent().parent().parent().parent();
               $(parent).append('<input class="sub_total" type="hidden" product-id="' + productcode + '" value="' + data.toFixed(2) + '">');
               reCalculateSub(parent);
            }
        });
    } else {
        $('.product_price.' + productcode).text('€0,00');
        $('input[product-id="' + productcode + '"]').val(0);
        var parent = $(that).parent().parent().parent().parent().parent();
        reCalculateSub(parent);

    }

}

function reCalculateSub(parent)
{
    var closest = $(parent).closest('.section-wrapper');

    price = 0;
    $(closest).find('.sub_total').each(function(){
        price += parseFloat($(this).val());
    });

    var sub_tot = $(parent).closest('.section-wrapper').attr('part');
    var sub_title = $(parent).closest('.section-wrapper').attr('sub-title');

    $('.subtotal.' + sub_tot).text('€' + price.toFixed(2).toString().replace(/\./g, ','));
    if (!$('.totalen .' + sub_tot )[0]){
        $('.excl-expl').append('<div class="col-md-8 explenation-block non-bold">' + sub_title + '</div><div class="col-md-4 non-bold price-block '+ sub_tot.toLowerCase() + '">€ ' + price.toFixed(2).toString().replace(/\./g, ',') + '</div>');
    } else {
        $('.totalen .' + sub_tot).text('€' + price.toFixed(2).toString().replace(/\./g, ','));
    }

    reCalculateTotal();
}

function reCalculateKorting(parent)
{
    var closest = $(parent).closest('.korting-wrapper');
    price = 0;
    $(closest).find('.sub_total').each(function(){
        price += parseFloat($(this).val().replace(/\,/g, '.'));
    });

    var sub_tot = $(parent).closest('.korting-wrapper').attr('part');
    var sub_title = $(parent).closest('.korting-wrapper').attr('sub-title');

    $('.subtotal.' + sub_tot).text('€' + price.toFixed(2).toString().replace(/\./g, ','));
    if (!$('.totalen .' + sub_tot )[0]){
        if (price != 0) {
            $('.excl-expl').append('<div class="col-md-8 explenation-block non-bold" style="font-style:italic;">' + sub_title + '</div><div style="font-style:italic;" class="col-md-4 non-bold price-block '+ sub_tot.toLowerCase() + '">- € ' + price.toFixed(2).toString().replace('-', '').replace(/\./g, ',') + '</div>');
        }
    } else {
        $('.totalen .' + sub_tot).text('- €' + price.toFixed(2).toString().replace(/\./g, ',').replace('-', ''));
    }

    reCalculateTotal();
}

// Herbereken totaal
function reCalculateTotal()
{
    var total = 0;
    $('.subtotal').each(function(){
        var sub_total = $(this).text().split('€')[1];
        total += parseFloat(sub_total);
    });

    var vat = parseFloat(((total * 1.06) - total).toFixed(2));

    $('.total-excl').text('€' + total.toFixed(2).toString().replace(/\./g, ','));
    $('.vat').text('€' + vat.toFixed(2).toString().replace(/\./g, ','));
    $('.total-incl').text('€' + (total + vat).toFixed(2).toString().replace(/\./g, ','));
}


function calculateExtra(that)
{
    var total = 0;
    var part = $(that).closest('.section-wrapper');

    $('input[xtr]').each(function(){
        // total += parseFloat($(this).val());
        var name = $(this).attr('name');

        if (!name) {
            return;
        }
        name = name.split('extra_total_');
        name = name[1];

        // Toevoeging om extra's ook in plat dak te integeren
        if (!name) {
            var name = $(this).attr('name');
            name = name.split('extra_plat_total_');
            name = name[1];
        }

        var val = ($(this).val()) ? $(this).val() : 0;
        if (!$('input[product-id= ' + name + '].extra')[0]) {
            $(part).append('<input class="sub_total extra" product-id="' + name + '" type="hidden" value="' + parseFloat(val) + '">');
        } else {
            $('input[product-id= ' + name + '].extra').val(val);
        }
        reCalculateSub(part);
    });
}

function calculateKorting(that)
{
    var total = 0;
    var part = $('.korting-wrapper');

    $('input[kort]').each(function(){
        // total += parseFloat($(this).val());
        var name = $(this).attr('name');
        name = name.split('korting_total_');
        name = name[1];

        // Toevoeging om extra's ook in plat dak te integeren
        if (!name) {
            var name = $(this).attr('name');
            name = name.split('korting_plat_total_');
            name = name[1];
        }

        var val = ($(this).val()) ? $(this).val().replace(/\,/g, '.') : 0;
        if (!$('input[product-id= ' + name + '].korting')[0]) {
            $(part).append('<input class="sub_total korting" product-id="' + name + '" type="hidden" value="-' + parseFloat(val) + '">');
        } else {
            $('input[product-id= ' + name + '].korting').val(-val);
        }
        reCalculateKorting(part);
    });
}

function addExtra(that)
{
    var total = parseInt($(that).attr('total')) + 1;
    $(that).attr('total', total);

    $(that).parent().before('<div class="extra-cost" cost="' + total + '" totals="' + total + '"><div class="col-md-6"><fieldset style="float:left; width: 90%;"><input type="text" name="extra_title_' + total + '"></fieldset></div><div class="col-md-3"><fieldset style="float:left; width: 90%;"><input type="text" name="extra_eenheid_' + total + '"> <div class="eenheid">st</div></fieldset></div><div class="col-md-3"><fieldset style="float:left; width: 90%;"><input type="text" xtr="test" name="extra_total_' + total + '"> <div class="eenheid">€</div></fieldset></div></div>');
}

function addBlanco(that)
{
    var total = parseInt($(that).attr('total')) + 1;
    $(that).attr('total', total);

    if (total <= 5) {
        total = 6;
    }

    if ($('input.total_r')[0]) {
        $('input.total_r').val(total);
    } else {
        $('form.blanco').append('<input type="hidden" class="total_r" name="total_r" value="' + total + '">');
    }

    $(that).parent().before('<div class="extra-cost" cost="' + total + '" totals="' + total + '"><div class="col-md-9"><fieldset style="float:left; width: 90%;"><input type="text" name="kost_title_' + total + '"></fieldset></div><div class="col-md-3"><fieldset style="float:left; width: 90%;"><input type="text" kost="test" name="kost_' + total + '"> <div class="eenheid">€</div></fieldset></div></div>');
}

function addExtraPlat(that)
{
    var total = parseInt($(that).attr('total')) + 1;
    $(that).attr('total', total);

    $(that).parent().before('<div class="extra-cost" cost="' + total + '" totals="' + total + '"><div class="col-md-6"><fieldset style="float:left; width: 90%;"><input type="text" name="extra_plat_title_' + total + '"></fieldset></div><div class="col-md-3"><fieldset style="float:left; width: 90%;"><input type="text" name="extra_plat_eenheid_' + total + '"> <div class="eenheid">st</div></fieldset></div><div class="col-md-3"><fieldset style="float:left; width: 90%;"><input type="text" xtr="test" name="extra_plat_total_' + total + '"> <div class="eenheid">€</div></fieldset></div></div>');
}

function calcKosten()
{
    var total = 0;

    $('[kost]').each(function(){
        if ($(this).val() != "" && $.isNumeric($(this).val().replace(',', '.'))) {
            total += parseFloat($(this).val().replace(',', '.'), 10);
        }
    });

    // if ($('[name="hidden-total"]').attr('value') != 0) {
    //     var total = $('[name="hidden-total"]').attr('value');
    // }

    if (total) {
        var total = total.toString().replace('.', ',');
        $('[name="totaal_kost"]').val(total);
    }

}

function addKorting(that)
{
    var total = parseInt($(that).attr('total')) + 1;
    $(that).attr('total', total);

    $(that).parent().before('<div class="korting-cost" cost="' + total + '" totals="' + total + '"><div class="col-md-9"><fieldset style="float:left; width: 98%;"><input type="text" name="korting_title_' + total + '"></fieldset></div><div class="col-md-3"><fieldset style="float:left; width: 90%;"><input type="text" kort="test" name="korting_total_' + total + '"> <div class="eenheid">€</div></fieldset></div></div>');
}

function addKortingPlat(that)
{
    var total = parseInt($(that).attr('total')) + 1;
    $(that).attr('total', total);

    $(that).parent().before('<div class="korting-cost" cost="' + total + '" totals="' + total + '"><div class="col-md-9"><fieldset style="float:left; width: 98%;"><input type="text" name="korting_plat_title_' + total + '"></fieldset></div><div class="col-md-3"><fieldset style="float:left; width: 90%;"><input type="text" kort="test" name="korting_plat_total_' + total + '"> <div class="eenheid">€</div></fieldset></div></div>');
}


// Show input
function showInput(that)
{
    var id = $(that).attr('trigger-id');
    $('.hidden-input.' + id).fadeToggle('fast');
    var height = $('.hidden-input.' + id).parent().height();

    if(!$(that).is(':checked')) {
        $('.hidden-input.' + id + ' input').val(0);
        calcProductPrice($(that).parent().parent().parent().parent().find('.hidden-input input'));
        if($(that).parent().parent().parent().parent().parent().find('input[type="checkbox"]').is(':checked')){
            $(that).parent().parent().parent().parent().parent().css({'height':height+20})
        } else {
            $(that).parent().parent().parent().parent().parent().css({'height':'auto'})
            //$(that).parent().parent().parent().parent().parent().css({'height':'75px'})
        }

        if ($(that).val() == 'VGO') {
            $('.reminder.gordingen').hide();
        }


    } else {
        $(that).parent().parent().parent().parent().parent().css({'height': (height+20)});

        if ($(that).val() == 'VGO') {
            $('.reminder.gordingen').show();
        }
    }
}

// Check if next button is allowed
function isValid(that)
{
    if ($(that).val() != "" && !isNaN($(that).val().replace(',', '.'))) {
        $(that).parent().parent().parent().parent().parent().find('.disabled').removeClass('disabled').addClass('next');
    } else {
        $(that).parent().parent().parent().parent().parent().find('.next').removeClass('next').addClass('disabled');
    }
}

// When step is re-opened
function reOpen(that, open)
{
    if (open) {
        $(that).removeClass('done').addClass('reopened');
    } else {
        $(that).removeClass('reopened').addClass('done');
    }
}

// Toggle next section
function nextSection(event, that)
{
    if ($('input[name="type_project"]')[0] && !isOk($(that))) {
        return false;
    }


    $('h3.error-msg').remove();
    $('.update-me').each(function(){
        $(this).keydown().keypress().keyup().blur();
    });

    var step = $('.section-wrapper.active').attr('step');
    $('.active h2').find('.fa').removeClass('fa-angle-down').addClass('fa-angle-up');

    var total = $('[part][step]').length;
    console.log(step >= total || !step);
    if (step >= total || !step) {
        $('.open-done section').slideUp();
        $('.open-done').css({'display': 'block'}).removeClass('open-done').addClass('done');
    }

    var that = $(that).parent();

    if (!isReqFilled($(that), step)) {
        return false;
    }

    if ($(that).attr('proj') == 'dakwerken') {
        $('form').show();
    }

    if ($(that).attr('proj') == 'gevelisolatie') {
        $('.gevelisolatie').show();
        handleGevelIsolatieSteps($(this));
        return;
    }

    if ($(that).attr('proj') == 'duurzaam') {
        $('.duurzaam').show();
        // handleDuurzaamSteps($(this));
        // return;
    }

    if ($(that).attr('proj') == 'zolderisolatie') {
        $('.zolderisolatie').show();
    }

    if ($(that).attr('proj') == 'ontmossen') {
        $('.ontmossen').show();
    }

    if ($(that).attr('proj') == 'blanco') {
        $('.blanco').show();
    }

    if ($(that).attr('dak') == 'hellend') {
        $('.dakwerken.plat').hide();
        $('.dakwerken.hellend').show();
    }
    if ($(that).attr('dak') == 'plat') {
        $('.dakwerken.plat').show();
        $('.dakwerken.hellend').hide();
    }
    if ($(that).attr('dak') == 'combinatie') {
        $('.dakwerken.combi').show();
        $('.dakwerken.combi').show();
    }

    // Stel extra vraag (Bv: leien = asbest?)
    if ($(that).find('input').val() == "Leien") {
        $('.asbest').slideDown();
        return false;
    }

    // Stel extra vraag (Bv: 1,1mm of 1,52mm)
    // if ($(that).find('input').val() == "EPDM") {
    //     $('.epdm').slideDown();
    //     return false;
    // }

    if (step == 1) {
        var project = $(that).find('input').attr('project');

        $('.project').hide();
        $('.project.' + project).show();
    }
    if (step == 5) {
        // var size = $('input[name="project_size"]').val();
        //
        // $('input[name="STO"]').click();
        // $('input[name="STO_hoeveelheid"]').val(size).keydown().keypress().keyup().blur();
        $('input[name="STO_hoeveelheid"]').parent().parent().parent().parent().css({height: '132px'});
        //
        // if (size <= 74) {
        //     var val = 1;
        // } else {
        //     var val = 2;
        // }
        // $('input[name="BMA"]').click();
        // $('input[name="BMA_hoeveelheid"]').val(val).keydown().keypress().keyup().blur();
        $('input[name="BMA_hoeveelheid"]').parent().parent().parent().parent().css({height: '132px'});
    }

    if (step == 6 && $('.info')[0] && !$('input[name="uitrechten"]:checked').val() && $('input[name="dakconstructie"]:checked').val() != 'volledig'){
        if ($('input[name="uitrechten"]:checked').val() != 'nee') {
            return false;
        }
    }

    if ((step == 7 && !$('.info')[0]) || (step != 7 || $(that).hasClass('informatie')) && (!$(that).closest('.section-wrapper[step]').hasClass('last-step') || !$('.info')[0])) {
        $('.section-wrapper.active .project-types').slideUp(function(){
            $('.active h2').find('.fa').removeClass('fa-angle-up').addClass('fa-check');
            $('.section-wrapper.active').removeClass('active').addClass('done');

            $('.section-wrapper[step=' + (parseInt(step)+1) + ']').addClass('active').removeClass('done');
            $('.active h2').find('.fa').removeClass('fa-angle-up').addClass('fa-angle-down');
            $('.section-wrapper.active .project-types').slideDown();
        });
    } else {
        $('form').each(function(){
            if ($(this).find('.project').is(':visible')) {
                $(this).find('.submit-field').show();
            }
        });
    }

    if (step == 5 && $('[name="type_dak"]:checked').val() == 'Plat dak') {
        $('form').each(function(){
            if ($(this).find('.project').is(':visible')) {
                $(this).find('.submit-field').show();
            }
        });
    }
}

function handleGevelIsolatieSteps(that)
{
    var step = $('.section-wrapper.active').attr('step');

    if (step == 1) {
        $('.section-wrapper.active .project-types').slideUp(function(){
            $('.active h2').find('.fa').removeClass('fa-angle-up').addClass('fa-check');
            $('.section-wrapper.active').removeClass('active').addClass('done');

            $('.section-wrapper[step=' + (parseInt(step)+1) + ']').addClass('active').removeClass('done');
            $('.active h2').find('.fa').removeClass('fa-angle-up').addClass('fa-angle-down');
            $('.section-wrapper.active .project-types').slideDown();
        });
    }

    $('.active h2').find('.fa').removeClass('fa-angle-down').addClass('fa-angle-up');

    $('.gevelisolatie.project').slideDown();

    // $('.submit-field').show();
}

function handleDuurzaamSteps(that)
{
    var step = $('.section-wrapper.active').attr('step');
    console.log(step);
    if (step == 1) {
        $('.section-wrapper.active .project-types').slideUp(function(){
            $('.active h2').find('.fa').removeClass('fa-angle-up').addClass('fa-check');
            $('.section-wrapper.active').removeClass('active').addClass('done');

            $('.section-wrapper[step=' + (parseInt(step)+1) + ']').addClass('active').removeClass('done');
            $('.active h2').find('.fa').removeClass('fa-angle-up').addClass('fa-angle-down');
            $('.section-wrapper.active .project-types').slideDown();
        });
    }

    if (step == 2) {

        if (!$('[name="type_installatie"]').is(':checked')) {
            event.preventDefault();
        } else {
            $('.section-wrapper.active .project-types').slideUp(function(){
                $('.active h2').find('.fa').removeClass('fa-angle-up').addClass('fa-check');
                $('.section-wrapper.active').removeClass('active').addClass('done');

                $('.section-wrapper[step=' + (parseInt(step)+1) + ']').addClass('active').removeClass('done');
                $('.active h2').find('.fa').removeClass('fa-angle-up').addClass('fa-angle-down');
                $('.section-wrapper.active .project-types').slideDown();
            });
        }


    }

    $('.active h2').find('.fa').removeClass('fa-angle-down').addClass('fa-angle-up');

    $('.gevelisolatie.project').slideDown();

    // $('.submit-field').show();
}

$('.add-condensatie').on('click', function(){
    var visible = $(this).attr('visible');
    $('[cond_extra="' + (parseInt(visible)+1) + '"]').show();
    $(this).attr('visible', parseInt(visible)+1);
});

$('.add-zonneboiler').on('click', function(){
    var visible = $(this).attr('visible');
    $('[zon_extra="' + (parseInt(visible)+1) + '"]').show();
    $(this).attr('visible', parseInt(visible)+1);
});

$('.add-zonnepanelen').on('click', function(){
    var visible = $(this).attr('visible');
    $('[zp_extra="' + (parseInt(visible)+1) + '"]').show();
    $(this).attr('visible', parseInt(visible)+1);
});

// Toggle section
function toggleSection(that)
{
    var step = $('.section-wrapper.active').attr('step');
    var request = $(that).parent().attr('step');
    var total = $('[part][step]').length;

    if ($(that).parent().hasClass('done')) {
        $(that).parent().removeClass('done').addClass('open-done');
    } else {
        $(that).parent().removeClass('open-done').addClass('done');
    }

    if (step >= request || getUrlParameter('edit')) {
        var id = $(that).attr('section-id');
        $(that).next('section').slideToggle();
        // $('section.' + id).slideToggle();
        $(that).find('i').toggleClass('fa-angle-down fa-angle-up')
    } else {
        if (step < total && step) {
            $('html, body').animate({
                scrollTop: $('[step=' + step + ']').offset().top
            }, 'slow');
        } else {
            if (!$(that).parent().hasClass('done')) {
                $('[step="' + request + '"]').removeClass('done').addClass('open-done');
                $('[step="' + request + '"]').find('section').css({'display': 'block'});
            } else {
                $('[step="' + request + '"]').removeClass('open-done').addClass('done');
                $('[step="' + request + '"]').find('section').css({'display': 'none '});
            }


        }

    }
}

function getVariants()
{
    $('.variant.dropdown').each(function(){
        var productcode = $(this).attr('product-code');
        productcode = productcode.split('__')[1];
        var arVariants = [ ];

        $.ajax({
            url: '/varianten/' + productcode,
            type: 'GET',
            async: false,
            contentType: "application/json; charset=utf-8",
            dataType:"json",
            success: function (data){
                $.each(data, function(key, value) {
                    arVariants[key] = value.naam
                });
            }
        });

        for (var i = 0; i < arVariants.length; i++) {
            var option = '<option product-code="' + productcode + '" value="' + arVariants[i] + '">' + arVariants[i] + '</option>'
            $(this).append(option);
        }
    });
}

// Dakpannen kiezen
function getTriples(that)
{
    var nr = $(that).parent().parent().attr('nr');

    var productcode = $(that).attr('name');
    var products = [ ];
    var code = $(that).find('option:selected').val();

    $.ajax({
        url: '/triple/' + code,
        type: 'GET',
        async: false,
        contentType: "application/json; charset=utf-8",
        dataType:"json",
        success: function (data){
            $('.dakbedekking[nr="' + nr + '"] .scnd').empty();
            $('.dakbedekking[nr="' + nr + '"] .thrd').empty();

            var option = '<option value="0" selected="selected" disabled="disabled">Kies sub-type</option>';
            $('.dakbedekking[nr="' + nr + '"] .scnd').append(option);

            var option = '<option value="0" selected="selected" disabled="disabled">Kies kleur</option>';
            $('.dakbedekking[nr="' + nr + '"] .thrd').append(option);

            $.each(data, function(key, value) {
                var option = '<option product-code="' + productcode + '" value="' + value.id + '">' + value.naam + '</option>'
                $('.dakbedekking[nr="' + nr + '"] .scnd').append(option);
            });
        }
    });
}
function getTriplesSub(that)
{
    var nr = $(that).parent().parent().attr('nr');

    var productcode = $(that).attr('name');
    var products = [ ];
    var code = $(that).find('option:selected').val();

    $.ajax({
        url: '/triple/sub/' + code,
        type: 'GET',
        async: false,
        contentType: "application/json; charset=utf-8",
        dataType:"json",
        success: function (data){
            $('.dakbedekking[nr="' + nr + '"]  .thrd').empty();

            var option = '<option selected="selected" value="0" disabled="disabled">Kies kleur</option>';
            $('.dakbedekking[nr="' + nr + '"] .thrd').append(option);

            $.each(data, function(key, value) {
                var option = '<option product-code="' + productcode + '" value="' + value.id + '">' + value.naam + '</option>'
                $('.dakbedekking[nr="' + nr + '"] .thrd').append(option);
            });
        }
    });
}

function calcDakBedekking(that)
{
    var nr = $(that).parent().parent().attr('nr');

    var first = $('.dakbedekking[nr="' + nr + '"] .triple').find(':selected').val();
    var second = $('.dakbedekking[nr="' + nr + '"] .scnd').find(':selected').val();
    var third = $('.dakbedekking[nr="' + nr + '"] .thrd').find(':selected').val();

    if (second == 0) {
        return false;
    }

    var amount = $('.dakbedekking[nr="' + nr + '"] .DBD').val().replace(',', '.');
    var productcode = $('.dakbedekking[nr="' + nr + '"] .DBD').attr('class');
    var part = $('.dakbedekking[nr="' + nr + '"] .DBD').closest('.section-wrapper');

    $.ajax({
        url: '/product/triple/' + first + '/' + second + '/' + third + '/' + amount,
        type: 'GET',
        contentType: "application/json; charset=utf-8",
        dataType:"json",
        success: function (data) {
           $('.dakbedekking[nr="' + nr + '"] .product_price.___' + productcode).text('€' + data.toFixed(2).toString().replace(/\./g, ','));

           if ($('.dakbedekking[nr="' + nr + '"] .sub_total[product-id=' + productcode + ']')[0]) {
               $('.dakbedekking[nr="' + nr + '"] .sub_total[product-id=' + productcode + ']').remove();
           }
           var parent = $('.dakbedekking[nr="' + nr + '"] .DBD').parent().parent().parent().parent().parent();
           if ($('input[type="hidden"][product-id="' + productcode + '_' + nr + '"]')[0]) {
               $('input[type="hidden"][product-id="' + productcode + '_' + nr + '"]').remove();
           }
           $(part).append('<input class="sub_total" type="hidden" product-id="' + productcode + '_' + nr +  '" value="' + data.toFixed(2) + '">');
           reCalculateSub(parent);
        }
    });
}

function selectSame(that)
{
    var name = $(that).attr('name');
    var selected = $(that).find('option:selected').text();

    var k = $(that).attr('k');
    $('[name=voetstuk' + k + ']').find('option[vl="' + selected + '"]').prop('selected', true);
}

function selectSameVelux(that)
{
    var name = $(that).attr('name');
    var selected = $(that).find('option:selected').text();
    var k = $(that).attr('k');

    $('[name=afdekschelp_afmetingen' + k + ']').find('option[vl="' + selected + '"]').prop('selected', true);
}

// Init date pickers
function initDatePickers()
{
    $("#datepicker").datepicker({dateFormat: 'dd/mm/yy', firstDay: 1});
    $("#datepicker2").datepicker({dateFormat: 'dd/mm/yy', firstDay: 1});
}

// // Test AJAX connection [GET]
// function testConnection(name)
// {
//     $.ajax({
//         url: '/api/alive/' + name,
//         type: 'GET',
//         contentType: "application/json; charset=utf-8",
//         dataType:"json",
//         success: function (data) {
//             console.log(data);
//         }
//     });
// }
//
// // Register [POST]
// function register(email)
// {
//     $.ajaxSetup({
//         headers: {
//             'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
//         }
//     });
//
//     $.ajax({
//         url: '/api/register',
//         type: 'POST',
//         data: {'email': email},
//         success: function (data) {
//             console.log(data);
//         }
//     });
// }



// $('.lookup input[type="submit"]').on('click', function(){
//     var name = $('input[name=name]').val();
//     testConnection(name);
// });
//
// $('.insert input[type="submit"]').on('click', function(){
//     var email = $('input[name=email]').val();
//     register(email);
// });

function openPrices(that)
{
    $(that).parent().find('div.row.bx').slideToggle();
    $(that).parent().toggleClass('active');
}

function searchProduct(that)
{
    var keyword = $(that).find('input').val();

    $('a[keywords]').hide();

    $('a[keywords]').each(function(){
        if ($(this).attr('keywords').toLowerCase().indexOf(keyword.toLowerCase()) >= 0) {
            $(this).show();
        }
    });
}

function initDropzone()
{
    $(".dropzone").dropzone({
        maxFiles: 2000,
        url: "/upload/image",
        previewTemplate: '<div class="dz-preview dz-file-preview"><div class="dz-image"><img data-dz-thumbnail /></div><div class="dz-details"><div class="dz-size"><span data-dz-size></span></div><div class="dz-filename"><span data-dz-name></span></div></div><div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div><div class="dz-error-message"><span data-dz-errormessage></span></div><input type="text" name="placeholderx" class="extra_info_image" placeholder="Korte beschrijving"></div>',
        headers: {
            'x-csrf-token': document.querySelectorAll('meta[name=csrf-token]')[0].getAttributeNode('content').value,
        },
        success: function (file, response) {

            var length = $('.afb-count').length;
            $('[name="placeholderx"]').attr('name', response);
            $('.afbeeldingen').append('<input type="hidden" class="afb-count" value="' + response + '" name="afbeelding' + (length + 1) + '" >');
        }
    });

    $(".fancybox").fancybox({
		openEffect	: 'none',
		closeEffect	: 'none'
	});

    $('.dz-preview').on('click', function(){
        alert('test');
    });

    $("div#file").dropzone({ url: "/file/post" });
}

var getUrlParameter = function getUrlParameter(sParam) {
    var sPageURL = decodeURIComponent(window.location.search.substring(1)),
        sURLVariables = sPageURL.split('&'),
        sParameterName,
        i;

    for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');

        if (sParameterName[0] === sParam) {
            return sParameterName[1] === undefined ? true : sParameterName[1];
        }
    }
};


var $p = $('.offerte-maken .informatie .equal label');

var maxHeight = $p.map(function() {
    if ($(this).height() > 0) {
        return $(this).height();
    } else {
        return '80';
    }
}).get();
$p.height(Math.max.apply(this, maxHeight));

var getUrlParameter = function getUrlParameter(sParam) {
    var sPageURL = decodeURIComponent(window.location.search.substring(1)),
        sURLVariables = sPageURL.split('&'),
        sParameterName,
        i;

    for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');

        if (sParameterName[0] === sParam) {
            return sParameterName[1] === undefined ? true : sParameterName[1];
        }
    }
};

equalheight = function(container){

var currentTallest = 0,
     currentRowStart = 0,
     rowDivs = new Array(),
     $el,
     topPosition = 0;
 $(container).each(function() {

   $el = $(this);
   $($el).height('auto')
   topPostion = $el.position().top;

   if (currentRowStart != topPostion) {
     for (currentDiv = 0 ; currentDiv < rowDivs.length ; currentDiv++) {
       rowDivs[currentDiv].height(currentTallest);
     }
     rowDivs.length = 0; // empty the array
     currentRowStart = topPostion;
     currentTallest = $el.height();
     rowDivs.push($el);
   } else {
     rowDivs.push($el);
     currentTallest = (currentTallest < $el.height()) ? ($el.height()) : (currentTallest);
  }
   for (currentDiv = 0 ; currentDiv < rowDivs.length ; currentDiv++) {
     rowDivs[currentDiv].height(currentTallest);
   }
 });
}

$(window).load(function() {
  equalheight('.mijnoffertesblokken');
});

$(window).resize(function(){
  equalheight('.mijnoffertesblokken');
});


document.addEventListener('DOMContentLoaded',function(){
    // //chart 1
    // var quantitydonut = parseInt($('.contractvsdoel .quantitydonut').html());
    // var totaldonut = parseInt($('.contractvsdoel .totaldonut').html());
    // var totalpercentage = ((quantitydonut/totaldonut)*100).toFixed(0);
    // var nogtehalen = totaldonut - quantitydonut;
    // var totaalmaaltwee = totaldonut * 2;
    //
    // $('.contractvsdoel p.percentage').text(totalpercentage + '%');
    //
    // new Chartist.Pie('#contractvsdoel', {
    //     series: [quantitydonut, nogtehalen]
    // }, {
    //     donut: true,
    //     donutWidth: 25,
    //     donutSolid: true,
    //     startAngle: 270,
    //     total: totaalmaaltwee,
    //     showLabel: true
    // });



});

var delay = (function(){
  var timer = 0;
  return function(callback, ms){
    clearTimeout (timer);
    timer = setTimeout(callback, ms);
  };
})();
