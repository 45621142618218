$('.first-next').on('click', function(event){
    event.preventDefault();

    if ($('[name=type_dak]:checked').val() == 'Plat dak' && isOk($('[step="6"] .informatie'))) {
        $(this).closest('form').submit();
    }

    if ($('[name=type_dak]:checked').val() == 'Hellend dak' && isOk($('[step="7"] .informatie'))) {
        $(this).closest('form').submit();
    }

    if ($('[name=type_dak]:checked').val() == 'Combinatie' && isOk($('[step="10"] .informatie'))) {
        $(this).closest('form').submit();
    }

    if (getProjectType().toLowerCase() == 'ontmossen' && isOk($('[step="3"] .informatie'))) {
        $(this).closest('form').submit();
    }

    if (getProjectType().toLowerCase() == 'blanco') {
        $(this).closest('form').submit();
    }

    if (getProjectType().toLowerCase() == 'duurzaam') {
        $(this).closest('form').submit();
    }

    if (getProjectType().toLowerCase() == 'zolderisolatie' && isOk($('[step="3"] .informatie'))) {
        $(this).closest('form').submit();
    }

    if (getProjectType().toLowerCase() == 'gevels' && isOk($('[step="3"] .informatie'))) {
        $(this).closest('form').submit();
    }
});

// Check welk project er aangeduid werd
function getProjectType()
{
    return $('input[name="type_project"]:checked').attr('project');
}

// Validate Dakwerken
function validateDakwerken(step, parent)
{
    if (step == 1 || step == 2 || step == 4) {

        if ($(parent).has(':checked').length) {
            return true;
        }
    }

    var soort_dak = $('input[name="type_dak"]:checked').val().toLowerCase();

    // **** PLAT DAK ****
    if (soort_dak == 'plat dak') {
        // Stap 3: Dakbedekking (EPDM check)
        if (step == 3) {
            if ($('input[name="dakbedekking_plat"]:checked').val() == 'EPDM') {
                if ($('input[name="dakbedekking_dikte"]').is(':checked')) {
                    return true;
                } else {
                    $('.epdm').slideDown();
                }
            } else if($('input[name="dakbedekking_plat"]:checked').val() != undefined && $('input[name="dakbedekking_plat"]:checked').val() != 'EPDM') {
                return true;
            } else {
                return false;
            }
        }

        if (step == 5) {
            if (($('[name="oppervlakte_plat"]').val() != "" && $.isNumeric($('[name="oppervlakte_plat"]').val().replace(',', '.'))) && ($('[name="dakbedekking_plat_extra"]').val() != "" && $.isNumeric($('[name="dakbedekking_plat_extra"]').val().replace(',', '.')))) {
                return true;
            }
        }

        if (step == 6) {
            // Eerste de vaste velden checken (moeten altijd ingevuld worden)
            var arChecks = ['met_isolatie', 'door_huis', 'stelling_plat_nodig', 'parkeerverbod', 'kiezels_aanwezig'];
            var arValids = [];

            for (var i = 0; i < arChecks.length; i++) {
                if ($('[name="' + arChecks[i] + '"]').is(':checked')) {
                    arValids.push(arChecks[i]);
                }

                if (arChecks[i] == "stelling_plat_nodig" && $('[name="stelling_plat_nodig"]:checked').val() == 'Ja') {
                    arChecks.push('plaats_stelling', 'stelling[]');
                    if ($('[name="plaats_stelling"]').is(':checked')) {
                        if (!$.inArray('plaats_stelling', arValids)) {
                            arValids.push('plaats_stelling');
                        }
                    }

                    if ($('[name="stelling[]"]').has(':checked').length) {
                        arValids.push('stelling[]');
                    }
                }

                if (arChecks[i] == "parkeerverbod" && $('[name="parkeerverbod"]:checked').val() == 'Ja') {
                    arChecks.push('straat_breedte_plat', 'voetpad_plat');

                    if ($('[name="straat_breedte_plat"]').val() != "" && $.isNumeric($('[name="straat_breedte_plat"]').val().replace(',', '.'))) {
                        if ($.inArray('straat_breedte_plat', arValids) == -1) {
                            arValids.push('straat_breedte_plat');
                        }
                    }

                    if ($('[name="voetpad_plat"]').val() != "" && $.isNumeric($('[name="voetpad_plat"]').val().replace(',', '.'))) {
                        if ($.inArray('voetpad_plat', arValids) == -1) {
                            arValids.push('voetpad_plat');
                        }
                    }
                }

                if (arChecks[i] == "kiezels_aanwezig" && $('[name="kiezels_aanwezig"]:checked').val() == 'Ja') {
                    arChecks.push('kiezels_weg');

                    if ($('[name="kiezels_weg"]').has(':checked').length) {
                        arValids.push('kiezels_weg');
                    }
                }
            }

            // console.log(arChecks, arValids);
            if (arChecks.length == arValids.length) {
                return true;
            }
        }
    }

    // **** HELLEND DAK ****
    if (soort_dak == 'hellend dak') {
        if (step == 3) {
            if ($('input[name="dakbedekking"]:checked').val() == 'Leien') {
                if ($('input[name="asbest"]').is(':checked') && $('input[name="osb_onder"]').is(':checked')) {
                    return true;
                } else {
                    $('.asbest').slideDown();
                }
            } else if($('input[name="dakbedekking"]:checked').val() != undefined && $('input[name="dakbedekking"]:checked').val() != 'Leien') {
                return true;
            } else {
                return false;
            }
        }

        if (step == 5) {
            if (($('[name="oppervlakte_hellend"]').val() != "" && $.isNumeric($('[name="oppervlakte_hellend"]').val().replace(',', '.')))) {
                return true;
            }
        }

        if (step == 6) {
            if ($('input[name="dakconstructie"]:checked').val() == 'volledig') {
                $('form').each(function(){
                    if ($(this).find('.project').is(':visible')) {
                        $(this).find('.submit-field').show();
                    }
                });
                return true;
            } else {
                if ($('input[name="uitrechten"]:checked').length > 0) {
                    $('form').each(function(){
                        if ($(this).find('.project').is(':visible')) {
                            $(this).find('.submit-field').show();
                        }
                    });

                    return true;
                } else {
                    return false;
                }
            }
        }

        if (step == 7) {
            var arChecks = ['plaats_stelling', 'stelling[]', 'parkeerverbod', 'hoeveelheid_stellingen'];
            var arValids = [];

            for (var i = 0; i < arChecks.length; i++) {
                if ($('[name="' + arChecks[i] + '"]').is(':checked')) {
                    arValids.push(arChecks[i]);
                }

                if (arChecks[i] == 'hoeveelheid_stellingen') {
                    var nr = $('[name="hoeveelheid_stellingen"]').val();

                    if ($.isNumeric(nr.replace(',', '.'))) {
                        arValids.push('hoeveelheid_stellingen');
                    }

                    for (var j = 1; j <= nr; j++) {
                        arChecks.push('hoogte_' + j, 'breedte_' + j);

                        if ($('[name="hoogte_' + j + '"]').val() != "" && $.isNumeric($('[name="hoogte_' + j + '"]').val().replace(',', '.'))) {
                            if ($.inArray('hoogte_' + j, arValids) == -1) {
                                arValids.push('hoogte_' + j);
                            }
                        }

                        if ($('[name="breedte_' + j + '"]').val() != "" && $.isNumeric($('[name="breedte_' + j + '"]').val().replace(',', '.'))) {
                            if ($.inArray('breedte_' + j, arValids) == -1) {
                                arValids.push('breedte_' + j);
                            }
                        }
                    }
                }

                if (arChecks[i] == "parkeerverbod" && $('[name="parkeerverbod"]:checked').val() == 'Ja') {
                    arChecks.push('straat_breedte', 'voetpad');

                    if ($('[name="straat_breedte"]').val() != "" && $.isNumeric($('[name="straat_breedte"]').val().replace(',', '.'))) {
                        if ($.inArray('straat_breedte', arValids) == -1) {
                            arValids.push('straat_breedte');
                        }
                    }

                    if ($('[name="voetpad"]').val() != "" && $.isNumeric($('[name="voetpad"]').val().replace(',', '.'))) {
                        if ($.inArray('voetpad', arValids) == -1) {
                            arValids.push('voetpad');
                        }
                    }
                }
            }

            if (arChecks.length == arValids.length) {
                return true;
            }
        }
    }

    // **** COMBI DAK ****
    if (soort_dak == 'combinatie') {
        if (step == 3) {
            if ($('input[name="dakbedekking"]:checked').val() == 'Leien') {
                if ($('input[name="asbest"]').is(':checked')) {
                    return true;
                } else {
                    $('.asbest').slideDown();
                }
            } else if($('input[name="dakbedekking"]:checked').val() != undefined && $('input[name="dakbedekking"]:checked').val() != 'Leien') {
                return true;
            } else {
                return false;
            }
        }

        if (step == 5) {
            if (($('[name="oppervlakte"]').val() != "" && $.isNumeric($('[name="oppervlakte"]').val().replace(',', '.')))) {
                return true;
            }
        }

        if (step == 6) {
            if ($('input[name="dakconstructie"]:checked').val() == 'volledig') {
                $('form').each(function(){
                    if ($(this).find('.project').is(':visible')) {
                        $(this).find('.submit-field').show();
                    }
                });
                return true;
            } else {
                if ($('input[name="uitrechten"]:checked').length > 0) {
                    $('form').each(function(){
                        if ($(this).find('.project').is(':visible')) {
                            $(this).find('.submit-field').show();
                        }
                    });

                    return true;
                } else {
                    return false;
                }
            }
        }

        if (step == 7) {
            var arChecks = ['plaats_stelling', 'stelling[]', 'parkeerverbod', 'hoeveelheid_stellingen_combi'];
            var arValids = [];

            for (var i = 0; i < arChecks.length; i++) {
                if ($('[name="' + arChecks[i] + '"]').is(':checked')) {
                    arValids.push(arChecks[i]);
                }

                if (arChecks[i] == 'hoeveelheid_stellingen_combi') {
                    var nr = $('[name="hoeveelheid_stellingen_combi"]').val();

                    if ($.isNumeric(nr.replace(',', '.'))) {
                        arValids.push('hoeveelheid_stellingen_combi');
                    }

                    for (var j = 1; j <= nr; j++) {
                        arChecks.push('hoogte_' + j, 'breedte_' + j);

                        if ($('[name="hoogte_' + j + '"]').val() != "" && $.isNumeric($('[name="hoogte_' + j + '"]').val().replace(',', '.'))) {
                            if ($.inArray('hoogte_' + j, arValids) == -1) {
                                arValids.push('hoogte_' + j);
                            }
                        }

                        if ($('[name="breedte_' + j + '"]').val() != "" && $.isNumeric($('[name="breedte_' + j + '"]').val().replace(',', '.'))) {
                            if ($.inArray('breedte_' + j, arValids) == -1) {
                                arValids.push('breedte_' + j);
                            }
                        }
                    }
                }

                if (arChecks[i] == "parkeerverbod" && $('[name="parkeerverbod"]:checked').val() == 'Ja') {
                    arChecks.push('straat_breedte_combi', 'voetpad_combi');

                    if ($('[name="straat_breedte_combi"]').val() != "" && $.isNumeric($('[name="straat_breedte_combi"]').val().replace(',', '.'))) {
                        if ($.inArray('straat_breedte_combi', arValids) == -1) {
                            arValids.push('straat_breedte_combi');
                        }
                    }

                    if ($('[name="voetpad_combi"]').val() != "" && $.isNumeric($('[name="voetpad_combi"]').val().replace(',', '.'))) {
                        if ($.inArray('voetpad_combi', arValids) == -1) {
                            arValids.push('voetpad_combi');
                        }
                    }
                }
            }

            if (arChecks.length == arValids.length) {
                return true;
            }
        }

        if (step == 8) {
            if ($('input[name="dakbedekking_plat"]:checked').val() == 'EPDM') {
                if ($('input[name="dakbedekking_dikte"]').is(':checked')) {
                    return true;
                } else {
                    $('.epdm').slideDown();
                }
            } else if($('input[name="dakbedekking_plat"]:checked').val() != undefined && $('input[name="dakbedekking_plat"]:checked').val() != 'EPDM') {
                return true;
            } else {
                return false;
            }
        }

        if (step == 9) {
            if (($('[name="oppervlakte_plat_combi"]').val() != "" && $.isNumeric($('[name="oppervlakte_plat_combi"]').val().replace(',', '.'))) && ($('[name="dakbedekking_plat_extra_combi"]').val() != "" && $.isNumeric($('[name="dakbedekking_plat_extra_combi"]').val().replace(',', '.')))) {
                return true;
            }
        }

        if (step == 10) {
            var arChecks = ['met_isolatie', 'door_huis', 'stelling_nodig', 'kiezels_aanwezig'];
            var arValids = [];

            for (var i = 0; i < arChecks.length; i++) {
                if ($('[name="' + arChecks[i] + '"]').is(':checked')) {
                    arValids.push(arChecks[i]);
                }

                if (arChecks[i] == "stelling_nodig" && $('[name="stelling_nodig"]:checked').val() == 'Ja') {
                    arChecks.push('plaats_stelling');
                    if ($('[name="plaats_stelling"]').is(':checked')) {
                        if (!$.inArray('plaats_stelling', arValids)) {
                            arValids.push('plaats_stelling');
                        }
                    }
                }

                if (arChecks[i] == "kiezels_aanwezig" && $('[name="kiezels_aanwezig"]:checked').val() == 'Ja') {
                    arChecks.push('kiezels_weg');

                    if ($('[name="kiezels_weg"]').has(':checked').length) {
                        arValids.push('kiezels_weg');
                    }
                }
            }

            console.log(arChecks, arValids);
            if (arChecks.length == arValids.length) {
                return true;
            }
        }
    }


    return false;

    // var soort_dak = $('input[name="type_dak"]:checked').val().toLowerCase();

}

// Validate Zolder isolatie
function validateZolderisolatie(step, parent)
{
    if (step == 1 || step == 2) {
        if ($(parent).has(':checked').length) {
            $('form').each(function(){
                if ($(this).find('.project').is(':visible')) {
                    $(this).find('.submit-field').show();
                }
            });

            return true;
        }
    }

    if (step == 3) {
        if (($(parent).find('[name="zoldergat_afmetingen_lengte"]').val() != "" && $.isNumeric($(parent).find('[name="zoldergat_afmetingen_lengte"]').val().replace(',', '.')))
            && ($(parent).find('[name="zoldergat_afmetingen_breedte"]').val() != "" && $.isNumeric($(parent).find('[name="zoldergat_afmetingen_breedte"]').val().replace(',', '.')))
            && ($('[name="doorgang_gegarandeerd"]:checked').length > 0) && ($('[name="hoogte"]:checked').length > 0) && ($('[name="dak_hoek"]:checked').length > 0)) {
            return true;
        }
    }

    return false;
}

// Validate Ontmossen
function validateOntmossen(step, parent)
{
    if (step == 1 || step == 2) {
        if ($(parent).has(':checked').length) {
            $('form').each(function(){
                if ($(this).find('.project').is(':visible')) {
                    $(this).find('.submit-field').show();
                }
            });

            return true;
        }
    }

    if (step == 3) {
        if (($(parent).find('[name="oppervlakte"]').val() != "" && $.isNumeric($(parent).find('[name="oppervlakte"]').val().replace(',', '.'))) && ($('[name="hoogte_grond_goot"]:checked').length > 0)) {
            return true;
        }
    }

    return false;
}

// Validate Gevel isolatie
function validateGevelisolatie(step, parent)
{
    if (step == 1 || step == 2) {
        if ($(parent).has(':checked').length) {
            $('form').each(function(){
                if ($(this).find('.project').is(':visible')) {
                    $(this).find('.submit-field').show();
                }
            });

            return true;
        }
    }

    if (step == 3) {
        var arChecks = ['plaats_stelling', 'stelling[]', 'parkeerverbod', 'hoeveelheid_stellingen'];
        var arValids = [];

        for (var i = 0; i < arChecks.length; i++) {
            if ($('[name="' + arChecks[i] + '"]').is(':checked')) {
                arValids.push(arChecks[i]);
            }

            if (arChecks[i] == 'hoeveelheid_stellingen') {
                var nr = $(parent).find('[name="hoeveelheid_stellingen"]').val();

                if ($.isNumeric(nr.replace(',', '.'))) {
                    arValids.push('hoeveelheid_stellingen');
                }

                for (var j = 1; j <= nr; j++) {
                    arChecks.push('hoogte_' + j, 'breedte_' + j);

                    if ($(parent).find('[name="hoogte_' + j + '"]').val() != "" && $.isNumeric($(parent).find('[name="hoogte_' + j + '"]').val().replace(',', '.'))) {
                        if ($.inArray('hoogte_' + j, arValids) == -1) {
                            arValids.push('hoogte_' + j);
                        }
                    }

                    if ($(parent).find('[name="breedte_' + j + '"]').val() != "" && $.isNumeric($(parent).find('[name="breedte_' + j + '"]').val().replace(',', '.'))) {
                        if ($.inArray('breedte_' + j, arValids) == -1) {
                            arValids.push('breedte_' + j);
                        }
                    }
                }
            }

            if (arChecks[i] == "parkeerverbod" && $('[name="parkeerverbod"]:checked').val() == 'Ja') {
                arChecks.push('straat_breedte', 'voetpad');

                if ($('form:visible [name="straat_breedte"]').val() != "" && $.isNumeric($('form:visible [name="straat_breedte"]').val().replace(',', '.'))) {
                    if ($.inArray('straat_breedte', arValids) == -1) {
                        arValids.push('straat_breedte');
                    }
                }

                if ($('form:visible [name="voetpad"]').val() != "" && $.isNumeric($('form:visible [name="voetpad"]').val().replace(',', '.'))) {
                    if ($.inArray('voetpad', arValids) == -1) {
                        arValids.push('voetpad');
                    }
                }
            }
        }

        if (arChecks.length == arValids.length) {
            return true;
        }
    }
}


function validateDuurzaam(step, parent)
{
    $('.submit-field').show();
    return true;
    // console.log(step, parent);
    // if (step == 1 || step == 2) {
    //     if ($(parent).has(':checked').length) {
    //         $('form').each(function(){
    //             if ($(this).find('.project').is(':visible')) {
    //                 $(this).find('.submit-field').show();
    //             }
    //         });
    //
    //         return true;
    //     }
    // }
    //
    // if (step == 3) {
    //     var arChecks = ['plaats_stelling', 'stelling[]', 'parkeerverbod', 'hoeveelheid_stellingen'];
    //     var arValids = [];
    //
    //     for (var i = 0; i < arChecks.length; i++) {
    //         if ($('[name="' + arChecks[i] + '"]').is(':checked')) {
    //             arValids.push(arChecks[i]);
    //         }
    //
    //         if (arChecks[i] == 'hoeveelheid_stellingen') {
    //             var nr = $(parent).find('[name="hoeveelheid_stellingen"]').val();
    //
    //             if ($.isNumeric(nr.replace(',', '.'))) {
    //                 arValids.push('hoeveelheid_stellingen');
    //             }
    //
    //             for (var j = 1; j <= nr; j++) {
    //                 arChecks.push('hoogte_' + j, 'breedte_' + j);
    //
    //                 if ($(parent).find('[name="hoogte_' + j + '"]').val() != "" && $.isNumeric($(parent).find('[name="hoogte_' + j + '"]').val().replace(',', '.'))) {
    //                     if ($.inArray('hoogte_' + j, arValids) == -1) {
    //                         arValids.push('hoogte_' + j);
    //                     }
    //                 }
    //
    //                 if ($(parent).find('[name="breedte_' + j + '"]').val() != "" && $.isNumeric($(parent).find('[name="breedte_' + j + '"]').val().replace(',', '.'))) {
    //                     if ($.inArray('breedte_' + j, arValids) == -1) {
    //                         arValids.push('breedte_' + j);
    //                     }
    //                 }
    //             }
    //         }
    //
    //         if (arChecks[i] == "parkeerverbod" && $('[name="parkeerverbod"]:checked').val() == 'Ja') {
    //             arChecks.push('straat_breedte', 'voetpad');
    //
    //             if ($('form:visible [name="straat_breedte"]').val() != "" && $.isNumeric($('form:visible [name="straat_breedte"]').val().replace(',', '.'))) {
    //                 if ($.inArray('straat_breedte', arValids) == -1) {
    //                     arValids.push('straat_breedte');
    //                 }
    //             }
    //
    //             if ($('form:visible [name="voetpad"]').val() != "" && $.isNumeric($('form:visible [name="voetpad"]').val().replace(',', '.'))) {
    //                 if ($.inArray('voetpad', arValids) == -1) {
    //                     arValids.push('voetpad');
    //                 }
    //             }
    //         }
    //     }
    //
    //     if (arChecks.length == arValids.length) {
    //         return true;
    //     }
    // }
}

function isOk(that)
{
    var parent = $(that).closest('.section-wrapper[step]');
    var step = $(parent).attr('step');
    var project = getProjectType();
    
    if (project == 'dakwerken') {
        var valid = validateDakwerken(step, parent);
    } else if (project == 'zolderisolatie') {
        var valid = validateZolderisolatie(step, parent);
    } else if (project == 'ontmossen') {
        var valid = validateOntmossen(step, parent);
    } else if (project == 'gevels') {
        var valid = validateGevelisolatie(step, parent);
    } else if (project == "blanco") {
        var valid = validateDuurzaam(step, parent);
    } else if (project == "duurzaam") {
        var valid = true;
    }
    if (!valid) {
        if (!$('.section-wrapper.active').find('h3.error-msg')[0]) {
            $('.section-wrapper.active').find('section.project-types').before('<h3 class="error-msg">Gelieve alle velden in te vullen</h3>');
        }
    }

    return valid;
}
