function filterPipeline(that)
{
    var type = $(that).attr('for');
    var href = location.href;
    var month = $('[name="filter-value-month"]').val();
    var year = $('[name="filter-value-year"]').val();
    var user_id = $('[name="user_selected"]').val();

    if (!$(that).hasClass('active-filter')) {
        type = 'all';
    }

	$.ajax({
        url: '/filter/pipeline/' + user_id + '/' + type + '/' + month + '/' + year,
        type: 'GET',
        contentType: "application/json; charset=utf-8",
        dataType:"json",
		async: false,
        success: function (data) {
			$('.offerteopsomming.op-pipeline').empty();
			var html = "";
            var total = 0;

			$.each(data, function(key, value){
                var date = new Date(value.created_at);
                var d = date.getDate();
                var m = date.toLocaleString('nl-NL', { month: "short" });
                total += value.total;
                var html = '<li class="' + value.color + '"><a class="fulllinktoofferte" href="/offerte/' + value.id + '"></a><div class="datecirckle"><p><span class="dag">' + d + '</span><span class="maand">' + m + '</span></p></div><div class="informationperson"><h4>' + value.klant.achternaam + '</h4><p>' + value.klant.postcode + ' ' + value.klant.plaats + '</p></div><div class="priceofferte">€ ' + value.total + '</div></li>';
				$('.offerteopsomming.op-pipeline').append(html);
			});

            $('.totalbedragoffertes.pipe #total-pipe').text(total.toLocaleString('nl-NL', {minimumFractionDigits: 2}));
        }
    });
}
