function isReqFilled(that, step)
{
    var ok = true;

    if (step == 1 && $('.dakbedekking_weg')[0]) { // PLAT DAK
        if ($('[name="dakbedekking_behouden"]:checked').val()) {
            return true;
        } else {
            $('html, body').animate({
                scrollTop: $('[step=' + step + ']').offset().top
            }, 'slow');

            $('.schouw h5').css({'color': '#FF4A40'}).append('<i class="fa fa-exclamation-circle" aria-hidden="true"></i>');

            return false;
        }
    }

    // Gevelisolatie
    $('.error-input').removeClass('error-input');

    if (step == 1 && $('[name="crepi_opp"]')[0]) {

        // **** BEGIN CREPI ****
        if ($('[name="crepi_opp"]').val() != "" && $.isNumeric($('[name="crepi_opp"]').val().replace(',', '.'))) {
            if ($('select[name="korrel_dikte"] option:selected').val() == 0) {
                $('h5:contains("korreldikte")').addClass('error-input');

                ok = false;
            }

            if (!$('[name="crepi_isolatie"]:checked').val()) {
                $('h5:contains("Moet er geïsoleerd worden?")').first().addClass('error-input');

                ok = false;
            }

            if ($('[name="crepi_isolatie"]:checked').val() == 'Gedeeltelijk' && (!$('[name="crepi_iso_opp"]').val() != "" && !$.isNumeric($('[name="crepi_iso_opp"]').val().replace(',', '.')))) {
                $('h5:contains("Hoeveel m² moet worden geïsoleerd?")').first().addClass('error-input');

                ok = false;
            }
        }

        if (!ok) {
            $('html, body').animate({
                scrollTop: $('select[name="korrel_dikte"]').offset().top - 100
            }, 'slow');
        }
        // **** EINDE CREPI ****

        // **** BEGIN THERMO ****
        if ($('[name="thermo_opp"]').val() != "" && $.isNumeric($('[name="thermo_opp"]').val().replace(',', '.'))) {

            if (!$('[name="thermo_isolatie"]:checked').val()) {
                $('h5:contains("Moet er geïsoleerd worden?")').last().addClass('error-input');

                ok = false;
            }

            if ($('[name="thermo_isolatie"]:checked').val() == 'Gedeeltelijk' && (!$('[name="thermo_iso_opp"]').val() != "" && !$.isNumeric($('[name="thermo_iso_opp"]').val().replace(',', '.')))) {
                $('h5:contains("Hoeveel m² moet worden geïsoleerd?")').last().addClass('error-input');

                ok = false;
            }
        }

        if (!ok) {
            $('html, body').animate({
                scrollTop: $('select[name="vensters_meegeteld"]').offset().top - 100
            }, 'slow');
        }
        // **** EINDE THERMO ****
        //
        // // **** BEGIN THERMO ****
        if ($('[name="joris_opp"]').val() != "" && $.isNumeric($('[name="joris_opp"]').val().replace(',', '.'))) {



            if (!$('[name="joris_dikte"]').val()) {
                $('h5:contains("Dikte isolatie?")').last().addClass('error-input');

                ok = false;
            }
        }

        if (!ok) {
            $('html, body').animate({
                scrollTop: $('select[name="joris_dikte"]').offset().top - 100
            }, 'slow');
        }
        // **** EINDE THERMO ****

        if ((($('[name="thermo_opp"]').val() != "" && $.isNumeric($('[name="thermo_opp"]').val().replace(',','.'))) || ($('[name="crepi_opp"]').val() != "" && $.isNumeric($('[name="crepi_opp"]').val().replace(',', '.')))) && !$('[name="vensters_meegeteld"]:checked').val()) {
            $('h5:contains("Zijn de ramen en deuren meegeteld?")').last().addClass('error-input');

            ok = false;
        }

    }

    if (step == 3 && $('[name="crepi_opp"]')[0]) {

        if (!$('[name="blauwsteen_dorpels"]:checked').val()) {
            $('h5:contains("Blauwsteen dorpels nodig?")').addClass('error-input');

            ok = false;
        }

        if ($('[name="blauwsteen_dorpels"]:checked').val() == 'Ja') {

            if (!$('[name="hoeveelheid_blauwsteen"]').val() != "" && !$.isNumeric($('[name="hoeveelheid_blauwsteen"]').val().replace(',', '.'))) {
                $('h5:contains("Hoeveel?")').addClass('error-input');

                ok = false;
            } else {
                var hoev_blauwsteen = $('[name="hoeveelheid_blauwsteen"]').val();

                for (var i = 1; i <= hoev_blauwsteen; i++) {
                    if (!$('[name="blauwsteen_hoogte_' + i + '"]').val() != "" && !$.isNumeric($('[name="blauwsteen_hoogte_' + i + '"]').val().replace(',', '.')) ||
                        !$('[name="blauwsteen_breedte_' + i + '"]').val() != "" && !$.isNumeric($('[name="blauwsteen_breedte_' + i + '"]').val().replace(',', '.')) ||
                        !$('[name="blauwsteen_lm_' + i + '"]').val() != "" && !$.isNumeric($('[name="blauwsteen_lm_' + i + '"]').val().replace(',', '.'))
                    ) {
                        $('p:contains("Blauwsteen ' + i  + '")').addClass('error-input');

                        ok = false;
                    }
                }
            }
        }
    }

    if (ok) {
        return true;
    }

    return false;
}

$('.check-btn').on('click', function(event){
    event.preventDefault();

    if (isReqFilled($(this), 3)) {
        $(this).closest('form').submit();
    }

});
